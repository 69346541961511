import './ActiveSubscriptions.scss';
import AvailableSubscriptions from '../../AvailableSubscriptions/AvailableSubscriptions';
import ActiveSubcriptions from '../../SlideSubcriptions/ActiveSubcriptions';
import { useMediaQuery } from 'react-responsive';

const ActiveSubscriptions = ({ searchQuery, dateRange, key = 'activeSubscriptions' }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    return (
        <div className="active-subscription">
            <div className="session-title mt-3">
                <h4>Active Subscriptions</h4>
            </div>

            <div className="mt-4">
                <ActiveSubcriptions activeTab={key} searchQuery={searchQuery} dateRange={dateRange} />
            </div>

            {!isMobile && (
                <>
                    <div className="session-title mt-4">
                        <h4>Available Subscriptions</h4>
                    </div>

                    <div className="mt-4">
                        <AvailableSubscriptions />
                    </div>
                </>
            )}
        </div>
    );
};

export default ActiveSubscriptions;
