import './SessionDetailMobile.scss';
import { Breadcrumb, Row } from 'react-bootstrap';
import masterCard from '@icons/mastercard.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import visa from '@icons/visa.svg';
import paypal from '@icons/paypal.svg';
import amex from '@icons/amex.svg';
import { format } from 'date-fns';

const SessionDetailMobile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { cardType, last4, totalMoney, salesTax, transactionFees, duration, zone, plateNo, endTime, startTime } =
        location.state || {};
    const cardIcons = {
        MASTERCARD: masterCard,
        VISA: visa,
        AMERICAN_EXPRESS: amex,
        PAYPAL: paypal
    };
    return (
        <>
            {location.pathname !== '/parker/session-view-details' && (
                <div className="breadcrumb-session">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/parker/')}>Recent Sessions</Breadcrumb.Item>
                        <Breadcrumb.Item active>Session Details</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <div className="session-mob-details">
                <div className="title-section">
                    <p>
                        Visited on <strong> {startTime ? format(new Date(startTime), 'MMMM dd, yyyy') : 'N/A'} </strong>
                    </p>
                    <h4>Zone {zone}</h4>
                    {/* <span>6391 Elgin St. Celina</span> */}
                </div>
                <hr />
                <div className="heading-section mb-3">Visit Details</div>
                <Row className="data-section">
                    <div className="image-container-content">
                        <div className="content-row">
                            <p className="title">License Plate # </p>
                            <p className="text-data">{plateNo}</p>
                        </div>
                        <div className="content-row">
                            <p className="title">Date In :</p>
                            <p className="text-data">
                                {startTime ? format(new Date(startTime), 'MMMM dd, yyyy') : 'N/A'}
                            </p>
                        </div>
                        <div className="content-row">
                            <p className="title">Date Out :</p>
                            <p className="text-data">{endTime ? format(new Date(endTime), 'MMMM dd, yyyy') : 'N/A'}</p>
                        </div>
                        <div className="content-row">
                            <p className="title">Time In :</p>
                            <p className="text-data">{startTime ? format(new Date(startTime), 'hh:mm a') : ''} </p>
                        </div>
                        <div className="content-row">
                            <p className="title">Time Out :</p>
                            <p className="text-data">{endTime ? format(new Date(endTime), 'hh:mm a') : ''}</p>
                        </div>
                    </div>
                </Row>
                <hr />
                <div className="heading-section mb-3">Parking Charges</div>
                <Row className="data-section">
                    <div className="address-content">
                        <div className="content-row">
                            <p className="title">Duration :</p>
                            <p className="text-data">{duration}</p>
                        </div>
                        <div className="content-row">
                            <p className="title">Transaction Fee : </p>
                            <p className="text-data">${transactionFees || '0'}</p>
                        </div>
                        <div className="content-row">
                            <p className="title">Sales Tax :</p>
                            <p className="text-data">${salesTax}</p>
                        </div>
                    </div>
                </Row>
                <hr />

                <div className="heading-section mb-3">Total</div>
                <Row className="data-section">
                    <div className="address-content">
                        <div className="content-row">
                            <p className="title">Card Type :</p>
                            <div className="d-flex justify-content-end w-100">
                                <div className="masterCard">
                                    <img src={cardIcons[cardType?.toUpperCase()] || masterCard} alt="masterCard" />
                                </div>
                            </div>
                        </div>
                        <div className="content-row">
                            <p className="title">Last 4</p>
                            <p className="text-data">{last4}</p>
                        </div>
                        <div className="content-row">
                            <p className="title">Total :</p>
                            <p className="text-data">${totalMoney}</p>
                        </div>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default SessionDetailMobile;
