import { useState } from 'react';
import './Sessions.scss';
import SessionCards from '../../SessionCards/SessionCards';
import prevIcon from '@icons/prev-icon.svg';
import nextIcon from '@icons/next-icon.svg';

const Sessions = ({ sessionData }) => {
    const itemsPerPage = 9; // Number of cards per page
    const [currentPage, setCurrentPage] = useState(0);

    // Calculate total pages
    const totalPages = Math.ceil(sessionData.length / itemsPerPage);

    // Get the current page data
    const currentData = sessionData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const handlePrevClick = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
    };

    return (
        <div className="sessions">
            <div className="session-title mt-3">
                <h4>Sessions</h4>
            </div>
            <div className="mt-3">
                <SessionCards sessionData={currentData} />
            </div>
            <div className="pre-next-icon mt-3">
                <div
                    className={`prev-icon ${currentPage === 0 ? 'disabled' : 'enabled'}`}
                    onClick={currentPage > 0 ? handlePrevClick : null}
                >
                    <img
                        src={prevIcon}
                        alt="prevIcon"
                        style={{
                            color: currentPage === 0 ? '#AFACAC' : '#00AB36'
                        }}
                    />
                </div>
                <div
                    className={`next-icon ${currentPage === totalPages - 1 ? 'disabled' : 'enabled'}`}
                    onClick={currentPage < totalPages - 1 ? handleNextClick : null}
                >
                    <img
                        src={nextIcon}
                        alt="nextIcon"
                        style={{
                            color: currentPage === totalPages - 1 ? '#AFACAC' : '#00AB36'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Sessions;
