import { useDispatch, useSelector } from 'react-redux';
import { changeCollapsedForm, changeHiddenForm } from '@redux/sidebar/sidebar_slice';
import logo from '@icons/white-logo.svg';
import collapsedlogo from '@icons/level-logo.svg';
import menuIcon from '@icons/menu-icon.svg';
import './UserTopBar.scss';
import ProfileDropdown from '../Dashboard/DropDown/Profile/ProfileDropdown';

const UserTopBar = () => {
    const dispatch = useDispatch();
    const { transitionType, collapsedForm, hiddenForm } = useSelector((state) => state.sidebar);
    return (
        <div className="user-topbar">
            {transitionType === 'collapse/expand' && (
                <div className="topbar-left-items">
                    <img
                        className={`collapsed-logo ${collapsedForm ? 'show-logo' : 'hide-logo'}`}
                        src={collapsedlogo}
                        alt="brand-logo"
                    />
                    <img className={`logo ${collapsedForm ? 'hide-logo' : 'show-logo'}`} src={logo} alt="brand-logo" />
                    <img
                        src={menuIcon}
                        className={`menu-toggler ${collapsedForm ? 'remove-margin' : 'add-margin'}`}
                        onClick={() => dispatch(changeCollapsedForm(!collapsedForm))}
                    />
                </div>
            )}
            {transitionType === 'hide/show' && (
                <div className="topbar-left-items">
                    <img className={'collapsed-logo show-logo'} src={collapsedlogo} alt="brand-logo" />
                    <img className={'logo hide-logo'} src={logo} alt="brand-logo" />
                    <img
                        src={menuIcon}
                        className={'menu-toggler remove-margin'}
                        onClick={() => dispatch(changeHiddenForm(!hiddenForm))}
                    />
                </div>
            )}
            <div className="topbar-right-items">
                <ProfileDropdown />
            </div>
        </div>
    );
};

export default UserTopBar;
