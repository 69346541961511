import { useEffect, useState } from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import './ViewVehicle.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosWrapper from '../../../utils/api';
import { API_URL } from '../../../utils/apiUrl';
import { useSelector } from 'react-redux';
import { dateFormat } from '../../../utils/common';
import deleleIcon from '@icons/del-icon.svg';
import editIcon from '@icons/editMobile.svg';
import { useMediaQuery } from 'react-responsive';
import DeleteModal from '../DeleteModal/DeleteModal';
const ViewVehicle = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userToken } = useSelector((state) => state.auth);
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const { vehicleId } = location.state || {};

    const [vehicle, setVehicle] = useState({
        vehicleMake: '',
        licensePlate: '',
        province: '',
        model: '',
        year: '',
        color: '',
        createdAt: null,
        owner: null
    });

    useEffect(() => {
        if (vehicleId) {
            fetchVehicleDetails(vehicleId);
        }
    }, [vehicleId]);

    const fetchVehicleDetails = async (id) => {
        try {
            const { data } = await axiosWrapper('GET', API_URL.GET_VEHICLE_BY_ID(id), {}, userToken);

            setVehicle({
                vehicleMake: data?.vehicleMake || '--',
                licensePlate: data?.licensePlate || '--',
                province: data?.province,
                model: data?.model || '',
                year: String(data?.year) || '',
                color: data?.color || '',
                createdAt: data?.createdAt || '',
                owner: data?.owner || ''
            });
        } catch (error) {
            // Handle error if needed
        }
    };
    const handleDelete = async () => {
        try {
            await axiosWrapper('DELETE', API_URL.DELETE_VEHICLE(vehicleId), {}, userToken);
            setShowDeleteModal(false);
            navigate('/parker/vehicles');
        } catch (error) {
            setShowDeleteModal(false);
        }
    };
    // Open Delete Modal
    const openDeleteModal = () => {
        setShowDeleteModal(true);
    };
    return (
        <>
            <div className="breadcrumb-vehicles">
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate('/parker/vehicles')}>Vehicles</Breadcrumb.Item>
                    <Breadcrumb.Item active>View Vehicle</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="view-vehicle-modal">
                {isMobile ? (
                    <div className="session-title d-flex align-items-center- justify-content-between mb-4">
                        <h4>Vehicles Details</h4>
                        <div>
                            <img
                                src={editIcon}
                                alt="editIcon"
                                onClick={() =>
                                    navigate('/parker/edit-vehicle', {
                                        state: { vehicleId: vehicleId }
                                    })
                                }
                            />
                            <img
                                width={20}
                                height={20}
                                src={deleleIcon}
                                alt="Delete Icon"
                                onClick={() => openDeleteModal(vehicle)}
                                className="ms-2"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="session-title mb-4">
                        <h4>Vehicles Details</h4>
                    </div>
                )}

                <Row className="mt-4 mb-5">
                    <Col xs={6} sm={6} md={6} lg={3}>
                        <h5>Vehicle Name</h5>
                        <p>{vehicle.vehicleMake}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3}>
                        <h5>License Plate #</h5>
                        <p>{vehicle.licensePlate}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3} className="mt-3 mt-lg-0">
                        <h5>Province/State</h5>
                        <p>{vehicle.province}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3} className="mt-3 mt-lg-0">
                        <h5>Created Date</h5>
                        <p>{dateFormat(vehicle.createdAt)}</p>
                    </Col>
                </Row>
                <Row className="mt-4 mb-5 " style={{ wordBreak: 'break-all' }}>
                    <Col xs={6} sm={6} md={6} lg={3}>
                        <h5>Vehicle Year</h5>
                        <p>{vehicle.year}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3}>
                        <h5>Vehicle Color</h5>
                        <p>{vehicle.color}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3} className="mt-3 mt-lg-0">
                        <h5>Owner Name</h5>
                        <p>{vehicle.owner?.name}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3} className="mt-3 mt-lg-0">
                        <h5>Owner Email</h5>
                        <p>{vehicle.owner?.email}</p>
                    </Col>
                </Row>
            </div>
            <DeleteModal
                isOpen={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                handleConfirm={handleDelete}
                title="Delete Vehicle"
                body={`Are you sure you want to delete the vehicle "${vehicle?.vehicleMake}"?`}
            />
        </>
    );
};

export default ViewVehicle;
