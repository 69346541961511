import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeCollapsedForm, changeHiddenForm, changeActiveItem } from '@redux/sidebar/sidebar_slice';
import './ParkerSidebar.scss';

// import all static icons
import transactionIcon from '@icons/transaction-icon.svg';
import parkers from '@icons/parker-inactive.svg';
import reportIcon from '@icons/report-icon.svg';
import lprIcon from '@icons/lpr-icon.svg';
import logoutIcon from '@icons/logout-icon.svg';
import userCircle from '@icons/profile-circle.svg';
import redLogout from '@icons/logout-red.svg';
import locationIcon from '@icons/location-icon.svg';

import { useDispatch, useSelector } from 'react-redux';

// Import all light frame icons
import homeIcon from '@icons/home-2.svg';
import paymentIcon from '@icons/Frame 1321314704.svg'; // Locations
import parkerLocationIcon from '@icons/Frame 1321314705.svg'; // Subscriptions
import supportIcon from '@icons/Frame 1321314707.svg'; // Payments
import accountIcon from '@icons/Frame 1321314777.svg'; // Vehicles
import vehicleIcon from '@icons/Frame 1321314702.svg'; // Support
import subscriptionIcon from '@icons/money-time.svg'; // Account Settings subscriptionIcon accountIcon

import LogoutModal from '../LogoutModal/LogoutModal';
import SidebarItem from '../Dashboard/Sidebar/SidebarItem';

const ParkerSidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    // Redux states
    const { userInfo } = useSelector((state) => state.auth);
    const { transitionType, collapsedForm, hiddenForm, activeItem } = useSelector((state) => state.sidebar);

    // Local states
    const [showLogoutModal, setLogoutModal] = useState(false);
    const handleLogoutModal = () => setLogoutModal((prev) => !prev);

    const [sideBarItems, setSidebarItems] = useState([]);
    const [bottomItems, setBottomItems] = useState([]);

    // Define admin items
    const adminSideBarItems = [
        {
            id: 1,
            name: 'Locations',
            iconLight: locationIcon,
            linkTo: '/admin/'
        },
        {
            id: 2,
            name: 'Transactions',
            iconLight: transactionIcon,
            linkTo: '/admin/transactions'
        },
        {
            id: 3,
            name: 'Parkers',
            iconLight: parkers,
            linkTo: '/admin/parkers'
        },
        {
            id: 4,
            name: 'Report',
            iconLight: reportIcon,
            linkTo: '/admin/reports'
        },
        {
            id: 5,
            name: 'LPR',
            iconLight: lprIcon,
            linkTo: '/admin/lpr'
        },
        {
            id: 6,
            name: 'Logout',
            iconLight: logoutIcon
        }
    ];

    // Define user items
    const userSideBarItems = [
        {
            id: 1,
            name: 'Home',
            iconLight: homeIcon,
            linkTo: '/parker/'
        },
        {
            id: 2,
            name: 'Locations',
            iconLight: parkerLocationIcon,
            linkTo: '/parker/locations'
        },
        {
            id: 3,
            name: 'Subscriptions',
            iconLight: subscriptionIcon,
            linkTo: '/parker/subscriptions'
        },
        {
            id: 4,
            name: 'Payments',
            iconLight: paymentIcon,
            linkTo: '/parker/payments'
        },
        {
            id: 5,
            name: 'Vehicles',
            iconLight: vehicleIcon,
            linkTo: '/parker/vehicles'
        },
        {
            id: 6,
            name: 'Support',
            iconLight: supportIcon,
            linkTo: '/parker/support'
        }
        // Removed 'Account Settings' from main items
    ];

    // Items that appear at bottom of user sidebar
    const userBottomItems = [
        {
            id: 7,
            name: 'Account Settings',
            iconLight: accountIcon,
            linkTo: '/parker/account-settings'
        }
    ];

    // Sidebar item click handler
    const handleSideBarClick = (item) => {
        if (item.name === 'Logout') {
            handleLogoutModal();
            dispatch(changeActiveItem(1)); // Reset to default item or any other logic
        } else {
            dispatch(changeActiveItem(item.id));
            // If the sidebar is open on mobile, close it automatically after navigation
            if (window.screen.width < 768) {
                dispatch(changeHiddenForm(true));
            } else if (window.screen.width > 768 && window.screen.width < 992 && !collapsedForm) {
                dispatch(changeCollapsedForm(true));
            }
            navigate(item.linkTo);
        }
    };

    // Decide which items to show (admin or user) based on URL
    useEffect(() => {
        if (location.pathname.includes('admin')) {
            setSidebarItems(adminSideBarItems);
            setBottomItems([]);
        } else {
            setSidebarItems(userSideBarItems);
            setBottomItems(userBottomItems);
        }
        return () => {
            setSidebarItems([]);
            setBottomItems([]);
        };
    }, [location.pathname]);

    // Auto-select active menu item based on the current route
    const selectActiveItem = () => {
        const allItems = [...sideBarItems, ...bottomItems];
        const activeItemObj = allItems.find((item) => location.pathname === item.linkTo);
        if (activeItemObj) {
            dispatch(changeActiveItem(activeItemObj.id));
        }
    };

    useEffect(() => {
        selectActiveItem();
    }, [location.pathname, sideBarItems, bottomItems]);

    return (
        <>
            {/**
             * Key part:
             * We add 'sidebar-open' to .parker-wrapper if the sidebar is visible on mobile.
             * For 'hide/show', the sidebar is "open" when hiddenForm === false.
             */}
            <div className={`parker-wrapper ${transitionType === 'hide/show' && !hiddenForm ? 'sidebar-open' : ''}`}>
                {transitionType === 'collapse/expand' && (
                    <div className={`parker-sidebar ${collapsedForm ? 'collapse-sidebar' : 'expand-sidebar'}`}>
                        <div className="sidebar-main">
                            {sideBarItems.map((item) => (
                                <SidebarItem
                                    key={item.id}
                                    item={item}
                                    selectedItemId={activeItem}
                                    handleSideBarClick={handleSideBarClick}
                                />
                            ))}
                        </div>
                        {location.pathname.includes('parker') && (
                            <div className="sidebar-bottom">
                                {bottomItems.map((item) => (
                                    <SidebarItem
                                        key={item.id}
                                        item={item}
                                        selectedItemId={activeItem}
                                        handleSideBarClick={handleSideBarClick}
                                    />
                                ))}
                                {/* Profile & Logout */}
                                <div className="sidebar-profile" onClick={() => handleSideBarClick({ name: 'Logout' })}>
                                    <div className="profile-icon">
                                        {userInfo?.userIcon ? (
                                            <img src={userInfo.userIcon} alt="User Icon" />
                                        ) : (
                                            <img src={userCircle} alt="user-circle" />
                                        )}
                                        {!collapsedForm && <span className="profile-name">{userInfo?.name}</span>}
                                    </div>
                                    {!collapsedForm && (
                                        <div className="profile-info">
                                            <img src={redLogout} alt="Logout" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <LogoutModal isOpen={showLogoutModal} handleModal={handleLogoutModal} />
                    </div>
                )}

                {transitionType === 'hide/show' && (
                    <div className={`parker-sidebar ${hiddenForm ? 'hide-sidebar' : 'show-sidebar'}`}>
                        <div className="sidebar-main">
                            {sideBarItems.map((item) => (
                                <SidebarItem
                                    key={item.id}
                                    item={item}
                                    selectedItemId={activeItem}
                                    handleSideBarClick={handleSideBarClick}
                                />
                            ))}
                        </div>
                        {location.pathname.includes('parker') && (
                            <div className="sidebar-bottom">
                                {bottomItems.map((item) => (
                                    <SidebarItem
                                        key={item.id}
                                        item={item}
                                        selectedItemId={activeItem}
                                        handleSideBarClick={handleSideBarClick}
                                    />
                                ))}
                                {/* Profile & Logout */}
                                <div className="sidebar-profile" onClick={() => handleSideBarClick({ name: 'Logout' })}>
                                    <div className="profile-icon">
                                        {userInfo?.userIcon ? (
                                            <img src={userInfo.userIcon} alt="User Icon" />
                                        ) : (
                                            <img src={userCircle} alt="user-circle" />
                                        )}
                                        {!collapsedForm && <span className="profile-name">{userInfo?.name}</span>}
                                    </div>
                                    {!collapsedForm && (
                                        <div className="profile-info">
                                            <img src={redLogout} alt="Logout" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <LogoutModal isOpen={showLogoutModal} handleModal={handleLogoutModal} />
                    </div>
                )}
            </div>
        </>
    );
};

export default ParkerSidebar;
