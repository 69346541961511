import React from 'react';
import './SelectPaymentMethod.scss';
import master from '@icons/mastercard.svg';
import visa from '@icons/visa.svg';
import paypal from '@icons/paypal.svg';
import amex from '@icons/amex.svg';
import trash from '@icons/trash.svg';
import { Col, Row } from 'react-bootstrap';
import DeleteModal from '../Modals/DeleteModal/DeleteModal';

const SelectPaymentMethod = ({
    isMobile,
    cards,
    selectedCard,
    defaultCardId,
    onSelectCard,
    onSetDefaultCard,
    onDeleteCard,
    showModal,
    handleCloseModal,
    confirmDelete,
    cardToDelete,
    isLoading
}) => {
    const cardIcons = {
        MASTERCARD: master,
        VISA: visa,
        AMERICAN_EXPRESS: amex,
        PAYPAL: paypal
    };

    const handleSelect = (id) => {
        onSelectCard(id === selectedCard ? null : id);
    };

    return (
        <div>
            {cards &&
                cards.length > 0 &&
                cards.map((item) => (
                    <div
                        key={item.id}
                        className={`select-payment mt-3 ${selectedCard === item.id ? 'active-border' : ''}`}
                        onClick={() => handleSelect(item.id)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => e.key === 'Enter' && handleSelect(item.id)}
                    >
                        <Row>
                            <Col xl={7} lg={12} md={7} sm={10} xs={12} className="d-flex align-items-center">
                                <div className="card">
                                    <img
                                        src={cardIcons[item.cardBrand.toUpperCase()] || master}
                                        alt={`${item.cardBrand} logo`}
                                    />
                                </div>
                                <div className="card-details">
                                    <h5>
                                        {item.cardBrand} ending in {item.last4}
                                    </h5>
                                    <p>
                                        Expiry date {item.expMonth}/{item.expYear}
                                    </p>
                                </div>
                            </Col>
                            <Col xl={5} lg={12} md={5} sm={2} xs={12} className="end-content mt-md-0 mt-lg-0">
                                {item.id === defaultCardId ? (
                                    <div className="default-btn">
                                        <button type="button" disabled>
                                            Default
                                        </button>
                                    </div>
                                ) : (
                                    <div className="set-as-default">
                                        <button type="button" onClick={() => onSetDefaultCard(item.id)}>
                                            Set as Default
                                        </button>
                                    </div>
                                )}
                                <div
                                    className="trash"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteCard(item.id);
                                    }}
                                >
                                    <img src={trash} alt="Delete card" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))}

            <DeleteModal
                isOpen={showModal}
                handleClose={handleCloseModal}
                handleConfirm={confirmDelete}
                title="Confirm Disable Card"
                body="Are you sure you want to disable this card?"
                loading={isLoading}
            />
        </div>
    );
};

export default SelectPaymentMethod;
