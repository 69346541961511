import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './DeleteModal.scss';
import Loading from '../../Loading/Loading';

const DeleteModal = ({ isOpen, handleClose, handleConfirm, title, body, loading }) => {
    return (
        <Modal show={isOpen} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title || 'Confirm Deletion'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body || 'Are you sure you want to delete this item?'}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleConfirm} disabled={loading}>
                    {loading ? <Loading /> : 'Delete'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

DeleteModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    body: PropTypes.string
};

export default DeleteModal;
