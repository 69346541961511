import { useState, useEffect } from 'react';
import { Popover } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import searchNormal from '@icons/search-icon.svg';
import filterIcon from '@icons/filter-icon.svg';
import './DropdownFilter.scss';

const DropdownFilter = ({ options, selectedOptions, onChange, title }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);

    useEffect(() => {
        const filtered = options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredOptions(filtered);
    }, [searchTerm, options]);

    const handleCheckboxChange = (value, isChecked) => {
        if (!value.value || value?.label?.toLowerCase() === 'all cities') {
            onChange([]);
            return;
        }

        if (!isChecked && selectedOptions?.length > 0) {
            const newOptions = selectedOptions?.filter((option) => !option.value !== value);
            onChange(newOptions);
        } else {
            const newOptions = [...selectedOptions, value];
            onChange(newOptions);
            return;
        }
        // const updatedOptions = selectedOptions ? [...selectedOptions] : [];

        // if (updatedOptions.includes(value)) {
        //     updatedOptions.splice(updatedOptions.indexOf(value), 1);
        // } else {
        //     updatedOptions.push(updatedOptions);
        // }

        // onChange(value);
    };
    return (
        <>
            <OverlayTrigger
                trigger="click"
                placement={'bottom'}
                rootClose={true}
                overlay={
                    <Popover id="popover-positioned-bottom">
                        <Popover.Header as="h3" className="dropdown-header">
                            <div className="filter-input-container">
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <img src={searchNormal} alt="Search icon" className="search-icon" />
                            </div>
                        </Popover.Header>
                        <Popover.Body className="dropdown-items">
                            {filteredOptions.map((option) => {
                                return (
                                    <label key={option.value} className="checkbox-item">
                                        <input
                                            type="checkbox"
                                            // value={option.value}
                                            checked={
                                                selectedOptions &&
                                                Array.isArray(selectedOptions) &&
                                                selectedOptions?.length > 0
                                                    ? selectedOptions?.find(
                                                          (newOption) => newOption?.value === option?.value
                                                      )
                                                    : false
                                            }
                                            onChange={(e) => handleCheckboxChange(option, e.target.checked)}
                                        />
                                        {option.label}
                                    </label>
                                );
                            })}
                        </Popover.Body>
                    </Popover>
                }
            >
                <Button className="dropdown-trigger">
                    <span>{title}</span>
                    <img src={filterIcon} alt="filter icon" className="filter-icon" />
                </Button>
            </OverlayTrigger>
        </>
    );
};

export default DropdownFilter;
