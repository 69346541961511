import { useEffect } from 'react';
import { setSidebarState } from '@redux/sidebar/sidebar_slice';
import { useSelector, useDispatch } from 'react-redux';
import './mainpanel.scss';

const MainPanel = (props) => {
    const dispatch = useDispatch();

    const { transitionType, collapsedForm } = useSelector((state) => state.sidebar);

    useEffect(() => {
        const handleResize = () => {
            if (window.screen.width <= 768) {
                dispatch(
                    setSidebarState({
                        transitionType: 'hide/show',
                        collapsedForm: false,
                        hiddenForm: true
                    })
                );
            } else if (window.screen.width > 768 && window.screen.width <= 992) {
                dispatch(
                    setSidebarState({
                        transitionType: 'collapse/expand',
                        collapsedForm: true,
                        hiddenForm: false
                    })
                );
            } else {
                dispatch(
                    setSidebarState({
                        transitionType: 'collapse/expand',
                        collapsedForm: false,
                        hiddenForm: false
                    })
                );
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {transitionType === 'collapse/expand' && (
                <div
                    id="scrollable-div"
                    className={`main-panel ${
                        !props.isParker
                            ? collapsedForm
                                ? 'grow-main-panel'
                                : 'shrink-main-panel'
                            : collapsedForm
                              ? 'grow-main-parker-panel'
                              : 'shrink-main-parker-panel'
                    }`}
                >
                    <div className="main-content-wrapper">{props.children}</div>
                </div>
            )}
            {transitionType === 'hide/show' && (
                <div id="scrollable-div" className="main-panel hideorshow-transitiontype">
                    <div className="main-content-wrapper">{props.children}</div>
                </div>
            )}
        </>
    );
};
export default MainPanel;
