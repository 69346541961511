import Slider from 'react-slick';
import './ActiveSubcriptions.scss';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
// import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import axiosWrapper from '../../utils/api';
import { API_URL } from '../../utils/apiUrl';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import redMarker from '@icons/redMarker.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../redux/actions/actionTypes';
// Custom arrow components
const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                backgroundImage: "url('https://i.ibb.co/xMyLwVX/Vector.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '20px',
                height: '20px',
                zIndex: 1
            }}
            onClick={onClick}
        />
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                backgroundImage: "url('https://i.ibb.co/hfH2qrt/right-icon.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '20px',
                height: '20px',
                zIndex: 1
            }}
            onClick={onClick}
        />
    );
};
const ActiveSubcriptions = ({ searchQuery, dateRange }) => {
    // const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const [subscriptions, setSubscriptions] = useState([]);
    const { userToken } = useSelector((state) => state.auth);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

    useEffect(() => {
        fetchActiveSubscriptions();
    }, [searchQuery, dateRange]);

    const fetchActiveSubscriptions = async () => {
        const fromDate = dateRange?.[0] ? dateRange[0].format('YYYY-MM-DD') : '';
        const toDate = dateRange?.[1] ? dateRange[1].format('YYYY-MM-DD') : '';

        const params = new URLSearchParams();
        if (searchQuery) params.set('search', searchQuery);
        if (fromDate && toDate) {
            params.set('fromDate', fromDate);
            params.set('toDate', toDate);
        }

        const url = `${API_URL.GET_ACTIVE_SUBSCRIPTIONS}?${params.toString()}`;

        const response = await axiosWrapper('GET', url, {}, userToken);
        setSubscriptions(response.data);
    };

    // const handleCancelSubscription = async (subscriptionId) => {
    //     try {
    //         await axiosWrapper('POST', API_URL.CANCEL_SUBSCRIPTION(subscriptionId), {}, userToken);
    //         fetchActiveSubscriptions();
    //     } catch (error) {}
    // };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isMobile ? 1 : 2,
        slidesToScroll: isMobile ? 1 : 2,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleCancelClick = (subscription) => {
        setSelectedSubscription(subscription);
        setShowCancelModal(true);
    };

    const handleConfirmCancel = async () => {
        setLoading(true);
        if (selectedSubscription) {
            try {
                await axiosWrapper('POST', API_URL.CANCEL_SUBSCRIPTION(selectedSubscription._id), {}, userToken);
                fetchActiveSubscriptions();
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'subscriptionChange', data: true } });
                setShowCancelModal(false);
                setLoading(false);
            } catch (error) {
                setShowCancelModal(false);
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'subscriptionChange', data: false } });
                setLoading(false);
                setSelectedSubscription(null);
            }
        }
    };

    return (
        <div className="slide-content">
            {subscriptions.length === 0 ? (
                <p className="text-center">No active subscriptions found.</p>
            ) : isMobile ? (
                subscriptions.map((item) => (
                    <div key={item._id} className="slide-item">
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <div className="active-data">
                                    <div className="map-container">
                                        <Map
                                            initialViewState={{
                                                longitude: item?.location?.longitude,
                                                latitude: item?.location?.latitude,
                                                zoom: 14
                                            }}
                                            style={{ width: '100%', height: '200px' }}
                                            mapStyle="mapbox://styles/mapbox/streets-v11"
                                            mapboxAccessToken={MAPBOX_TOKEN}
                                        >
                                            <Marker
                                                longitude={item?.location?.longitude}
                                                latitude={item?.location?.latitude}
                                                anchor="center"
                                            >
                                                <img src={redMarker} alt="Marker" className="custom-marker" />
                                            </Marker>
                                        </Map>
                                    </div>
                                    <Row className="mt-3 align-items-start">
                                        <Col xs={9} sm={9}>
                                            <h4>{item?.location?.market}</h4>
                                        </Col>
                                        <Col xs={3} sm={3} className="status-section ">
                                            <div className="d-flex align-items-center">
                                                <div className="status-green"></div>
                                                <p>{item.status}</p>
                                            </div>
                                        </Col>
                                        <Col xs={10} sm={10}>
                                            <p>{`${item.location?.address}, ${item.location?.addressType}, ${item.location?.city}, ${item.location?.state}`}</p>
                                        </Col>
                                    </Row>
                                    <p className="mb-2">
                                        {' '}
                                        ${item?.amount / 100} Per {item?.frequency === 'Weekly' ? 'Week' : 'Month'}
                                    </p>
                                    <Row className="mt-3">
                                        <Col lg={12}>
                                            {/* <button
                                                className="manage-btn"
                                                type="button"
                                                disabled
                                                onClick={() =>
                                                    navigate('/parker/manage-subscription', {
                                                        state: { subscription: item }
                                                    })
                                                }
                                            >
                                                Active Subscription
                                            </button> */}
                                        </Col>
                                        <Col lg={12} className="mt-3">
                                            <button
                                                type="button"
                                                className="cancel-btn"
                                                onClick={() => handleCancelClick(item)}
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))
            ) : (
                <Slider {...settings}>
                    {subscriptions.map((item) => (
                        <div key={item._id} className="slide-item">
                            <Row>
                                <Col xs={12} sm={12}>
                                    <div className="active-data">
                                        <div className="map-container">
                                            <Map
                                                initialViewState={{
                                                    longitude: item?.location?.longitude,
                                                    latitude: item?.location?.latitude,
                                                    zoom: 14
                                                }}
                                                style={{ width: '100%', height: '200px' }}
                                                mapStyle="mapbox://styles/mapbox/streets-v11"
                                                mapboxAccessToken={MAPBOX_TOKEN}
                                            >
                                                <Marker
                                                    longitude={item?.location?.longitude}
                                                    latitude={item?.location?.latitude}
                                                    anchor="center"
                                                >
                                                    <img src={redMarker} alt="Marker" className="custom-marker" />
                                                </Marker>
                                            </Map>
                                        </div>
                                        <Row className="mt-3 address-bar d-flex align-items-center ">
                                            <Col lg={8} md={12}>
                                                <h4>{item?.location?.market}</h4>
                                                <p>{`${item.location?.address}, ${item.location?.addressType}, ${item.location?.city}, ${item.location?.state}`}</p>
                                            </Col>
                                            <Col lg={4} md={12} className="status-section mt-2 mt-lg-0">
                                                <div className="d-flex align-items-center">
                                                    <div className="status-green"></div>
                                                    <p>{item.status}</p>
                                                </div>
                                            </Col>
                                            <p className="mb-2">
                                                {' '}
                                                ${item?.amount / 100} Per
                                                {item?.frequency === 'Weekly' ? ' Week' : ' Month'}
                                            </p>
                                        </Row>
                                        {/* <Row className="mt-3">
                                            <Col lg={12}>
                                               <button
                                                    className="manage-btn"
                                                    type="button"
                                                    disabled
                                                    onClick={() =>
                                                        navigate('/parker/manage-subscription', {
                                                            state: { subscription: item }
                                                        })
                                                    }
                                                >
                                                    Active Subscription
                                                </button> 
                                            </Col>
                                        </Row> */}
                                        <Col lg={12} className="mt-5">
                                            <button
                                                type="button"
                                                className="cancel-btn"
                                                onClick={() => handleCancelClick(item)}
                                            >
                                                Cancel Subscription
                                            </button>
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Cancel Subscription</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to cancel the subscription for "
                            {selectedSubscription?.location?.market}"?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button disabled={loading} variant="secondary" onClick={() => setShowCancelModal(false)}>
                                No, Keep
                            </Button>
                            <Button disabled={loading} variant="danger" onClick={handleConfirmCancel}>
                                {loading ? <Spinner animation="border" size="sm" /> : 'Yes, Cancel'}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Slider>
            )}
        </div>
    );
};

export default ActiveSubcriptions;
