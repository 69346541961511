import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import circle from '@icons/profile-circle.svg';
import LogoutModal from '../../../LogoutModal/LogoutModal';
import './profiledropdown.scss';
import { useSelector } from 'react-redux';

const ProfileDropdown = () => {
    const { userInfo } = useSelector((state) => state.auth);
    //state to handle the modal
    const [showLogoutModal, setLogoutModal] = useState(false);
    const handleLogoutModal = () => setLogoutModal((pre) => !pre);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const toggleDropdown = () => {
        setShow(!show);
    };

    function navigator() {
        if (userInfo?.role === 'PARKER') {
            navigate('/parker/account-settings');
        } else {
            navigate('/admin/profile');
        }
    }

    return (
        <div className="profile-dropdown-class">
            <div className="line-border"></div>
            <Dropdown className="profile-dropdown" onToggle={toggleDropdown}>
                <Dropdown.Toggle id="profile-dropdown-toggle">
                    <img src={circle} alt="circle" />
                    <span className={userInfo?.role === 'ADMIN' ? 'color-black' : ''}>{userInfo?.name}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu show={show} id="profile-dropdown-menu">
                    <Dropdown.Item onClick={navigator}>Profile</Dropdown.Item>
                    <Dropdown.Item onClick={handleLogoutModal}>Logout</Dropdown.Item>
                </Dropdown.Menu>
                <LogoutModal onClick={handleLogoutModal} isOpen={showLogoutModal} handleModal={handleLogoutModal} />
            </Dropdown>
        </div>
    );
};

export default ProfileDropdown;
