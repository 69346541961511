import { useState, useEffect, useMemo } from 'react';
import './NearbyLocation.scss';
import searchNormal from '@icons/search-icon.svg';
import { Col, Row } from 'react-bootstrap';
import DropdownFilter from '../Dashboard/DropDown/Filter/DropdownFilter';
import SubcriptionDetailModal from '../Modals/SubcriptionDetailModal/SubcriptionDetailModal';
import { useMediaQuery } from 'react-responsive';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useLocation } from 'react-router-dom';
import redMarker from '@icons/redMarker.svg';
import { debounce } from 'lodash';

const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

const NearbyLocation = () => {
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [show, setShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [selectedLocationId, setSelectedLocationId] = useState(null);

    const [locations, setLocations] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = (item) => {
        setShow(true);
        setSelectedLocationId(item._id); // Use the unique identifier of the location
    };

    const defaultOptions = [
        { value: '', label: 'All Cities' },
        { value: 'New York', label: 'New York' },
        { value: 'Los Angeles', label: 'Los Angeles' }
    ];
    const handlePlatformChange = (selectedOption) => {
        setSelectedPlatform(selectedOption.value);
    };

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const locationState = useLocation();
    const nearbyLocations = locationState.state?.nearbyLocations || [];

    useEffect(() => {
        // Initially, set the locations to nearbyLocations passed via navigate
        setLocations(nearbyLocations);
        setFilteredLocations(nearbyLocations);
    }, [nearbyLocations]);

    // Debounced search handler
    const handleSearch = useMemo(
        () =>
            debounce((query) => {
                setSearchQuery(query);
            }, 300),
        []
    );

    const onSearchChange = (e) => {
        const query = e.target.value;
        setSearchInput(query);
        handleSearch(query);
    };

    useEffect(() => {
        // Filter locations based on search query and selected city
        let filtered = locations;

        if (searchQuery) {
            filtered = filtered.filter(
                (loc) =>
                    loc.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                    loc.address?.toLowerCase().includes(searchQuery?.toLowerCase())
            );
        }

        if (selectedPlatform) {
            filtered = filtered.filter((loc) => loc.city && loc.city.toLowerCase() === selectedPlatform.toLowerCase());
        }

        setFilteredLocations(filtered);
    }, [searchQuery, selectedPlatform, locations]);

    return (
        <div className="nearby-location">
            <Row className="align-items-center">
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <h4>Nearby Locations</h4>
                </Col>
                {!isMobile && (
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} className="d-flex justify-content-end mt-3 mt-xl-0">
                        <div className="right-items">
                            <div className="search-input-container">
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    value={searchInput}
                                    onChange={onSearchChange}
                                />
                                <img src={searchNormal} alt="Search icon" className="search-icon" />
                            </div>
                            <div className="dropdown-container">
                                <DropdownFilter
                                    options={defaultOptions}
                                    selectedOptions={selectedPlatform}
                                    onChange={handlePlatformChange}
                                    title={'Filter by City'}
                                />
                            </div>
                        </div>
                    </Col>
                )}
            </Row>
            <Row className="for-margin">
                {filteredLocations.length > 0 ? (
                    filteredLocations.map((item) => (
                        <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-3" key={item.id}>
                            <div className="active-data">
                                <div className="map-container">
                                    <Map
                                        initialViewState={{
                                            longitude: item.longitude,
                                            latitude: item.latitude,
                                            zoom: 14
                                        }}
                                        style={{ width: '100%', height: '200px' }}
                                        mapStyle="mapbox://styles/mapbox/streets-v11"
                                        mapboxAccessToken={MAPBOX_TOKEN}
                                        scrollZoom={false}
                                        doubleClickZoom={false}
                                        dragPan={false}
                                        dragRotate={false}
                                        touchZoomRotate={false}
                                    >
                                        <Marker longitude={item.longitude} latitude={item.latitude} anchor="center">
                                            <img src={redMarker} alt="Marker" className="custom-marker" />
                                        </Marker>
                                    </Map>
                                </div>
                                <Row className="mt-3">
                                    <Col>
                                        <h6 className="mb-1">Zone {item?.zone || ''}</h6>
                                        <h4>{item.name}</h4>
                                        <p>{item.address}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    {/* Commenting out for future use */}
                                    {/* <Col xs={12} sm={6}>
                                        <button className="manage-btn">Buy Now</button>
                                    </Col> */}
                                    <Col className="mt-3 mt-sm-0">
                                        <button className="manage-btn" type="button" onClick={() => handleShow(item)}>
                                            Subscribe
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    ))
                ) : (
                    <Col>
                        <p>No nearby locations found.</p>
                    </Col>
                )}
            </Row>
            <SubcriptionDetailModal selectedLocationId={selectedLocationId} show={show} handleClose={handleClose} />
        </div>
    );
};

export default NearbyLocation;
