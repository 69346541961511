import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Loading from '@components/Loading/Loading';

const ParkerAuthLayout = () => {
    return (
        <Suspense fallback={<Loading centered size="lg" />}>
            <Outlet />
        </Suspense>
    );
};

export default ParkerAuthLayout;
