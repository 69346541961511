import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeCollapsedForm, changeHiddenForm, changeActiveItem } from '@redux/sidebar/sidebar_slice';
import LogoutModal from '../../LogoutModal/LogoutModal';
import SidebarItem from './SidebarItem';
import './sidebar.scss';

// import all static icons
import transactionIcon from '@icons/transaction-icon.svg';
import parkers from '@icons/parker-inactive.svg';
import reportIcon from '@icons/report-icon.svg';
import lprIcon from '@icons/lpr-icon.svg';
import logoutIcon from '@icons/logout-icon.svg';
import userCircle from '@icons/profile-circle.svg';
import redLogout from '@icons/logout-red.svg';
import locationIcon from '@icons/location-icon.svg';
import { useDispatch, useSelector } from 'react-redux';

// Import all light frame icons
import homeIcon from '@icons/home-2.svg';
import paymentIcon from '@icons/Frame 1321314704.svg'; // Locations
import parkerLocationIcon from '@icons/Frame 1321314705.svg'; // Subscriptions
import supportIcon from '@icons/Frame 1321314707.svg'; // Payments
import accountIcon from '@icons/Frame 1321314777.svg'; // Vehicles
import vehicleIcon from '@icons/Frame 1321314702.svg'; // Support
import subscriptionIcon from '@icons/money-time.svg'; // Account Settings subscriptionIcon accountIcon
const Sidebar = () => {
    //state to handle the modal
    const [showLogoutModal, setLogoutModal] = useState(false);
    const handleLogoutModal = () => setLogoutModal((pre) => !pre);
    const { userInfo } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [sideBarItems, setSidebarItems] = useState([]);
    const [bottomItems, setBottomItems] = useState([]);
    const { transitionType, collapsedForm, hiddenForm, activeItem } = useSelector((state) => state.sidebar);

    const adminSideBarItems = [
        // Later we will un-comment these  once the feature will be ready
        {
            id: 1,
            name: 'Locations',
            iconLight: locationIcon,
            linkTo: '/admin/'
        },
        {
            id: 2,
            name: 'Transactions',
            iconLight: transactionIcon,
            linkTo: '/admin/transactions'
        },
        {
            id: 3,
            name: 'Parkers',
            iconLight: parkers,
            linkTo: '/admin/parkers'
        },
        {
            id: 4,
            name: 'Report',
            iconLight: reportIcon,
            linkTo: '/admin/reports'
        },
        {
            id: 5,
            name: 'LPR',
            iconLight: lprIcon,
            linkTo: '/admin/lpr'
        },
        {
            id: 6,
            name: 'Logout',
            iconLight: logoutIcon
        }
    ];

    const userSideBarItems = [
        {
            id: 1,
            name: 'Home',
            iconLight: homeIcon,
            linkTo: '/parker/'
        },
        {
            id: 2,
            name: 'Locations',
            iconLight: parkerLocationIcon,
            linkTo: '/parker/locations'
        },
        {
            id: 3,
            name: 'Subscriptions',
            iconLight: subscriptionIcon,
            linkTo: '/parker/subscriptions'
        },
        {
            id: 4,
            name: 'Payments',
            iconLight: paymentIcon,
            linkTo: '/parker/payments'
        },
        {
            id: 5,
            name: 'Vehicles',
            iconLight: vehicleIcon,
            linkTo: '/parker/vehicles'
        },
        {
            id: 6,
            name: 'Support',
            iconLight: supportIcon,
            linkTo: '/parker/support'
        }
        // Removed 'Account Settings' from main items
    ];
    const userBottomItems = [
        {
            id: 7,
            name: 'Account Settings',
            iconLight: accountIcon,
            linkTo: '/parker/account-settings'
        }
        // You can add more bottom items if needed
    ];

    const handleSideBarClick = (item) => {
        if (item.name === 'Logout') {
            handleLogoutModal();
            dispatch(changeActiveItem(1)); // Dispatch the selected item to update the activeLink
        } else {
            dispatch(changeActiveItem(item.id)); // Dispatch the selected item to update the activeLink
            //if the sidebar is not collapsed or hidden on mobile screens, the sidebar will auto close on the nav click
            if (window.screen.width < 768) {
                dispatch(changeHiddenForm(true));
            } else if (window.screen.width > 768 && window.screen.width < 992 && !collapsedForm) {
                dispatch(changeCollapsedForm(true));
            }

            navigate(item.linkTo); // Navigate to the new route
        }
    };

    useEffect(() => {
        if (location.pathname.includes('admin')) {
            setSidebarItems(adminSideBarItems);
            setBottomItems([]);
        } else {
            setSidebarItems(userSideBarItems);
            setBottomItems(userBottomItems);
        }

        return () => {
            setSidebarItems([]);
            setBottomItems([]);
        };
    }, [location.pathname]);

    const selectActiveItem = () => {
        const allItems = [...sideBarItems, ...bottomItems];
        // Determine the selected item based on the current route
        const activeItem = allItems.find((item) => location.pathname === item.linkTo);
        if (activeItem) {
            dispatch(changeActiveItem(activeItem.id));
        }
    };

    useEffect(() => {
        selectActiveItem();
    }, [location.pathname, sideBarItems, bottomItems]);

    return (
        <>
            {transitionType === 'collapse/expand' && (
                <div className={`sidebar ${collapsedForm ? 'collapse-sidebar' : 'expand-sidebar'}`}>
                    <div className="sidebar-main">
                        {sideBarItems.map((item) => (
                            <SidebarItem
                                key={item.id}
                                item={item}
                                selectedItemId={activeItem}
                                handleSideBarClick={handleSideBarClick}
                            />
                        ))}
                    </div>
                    {location.pathname.includes('parker') && (
                        <div className="sidebar-bottom">
                            {bottomItems.map((item) => (
                                <SidebarItem
                                    key={item.id}
                                    item={item}
                                    selectedItemId={activeItem}
                                    handleSideBarClick={handleSideBarClick}
                                />
                            ))}
                            <div className="sidebar-profile" onClick={() => handleSideBarClick({ name: 'Logout' })}>
                                <div className="profile-icon">
                                    {userInfo?.userIcon ? (
                                        <img src={userInfo?.userIcon} alt="User Icon" />
                                    ) : (
                                        <img src={userCircle} alt="user-circle" />
                                    )}
                                    {!collapsedForm && <span className="profile-name">{userInfo?.name}</span>}
                                </div>
                                {!collapsedForm && (
                                    <div className="profile-info">
                                        <img src={redLogout} alt="Logout" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <LogoutModal isOpen={showLogoutModal} handleModal={handleLogoutModal} />
                </div>
            )}
            {transitionType === 'hide/show' && (
                <div className={`sidebar ${hiddenForm ? 'hide-sidebar' : 'show-sidebar'}`}>
                    <div className="sidebar-main">
                        {sideBarItems.map((item) => (
                            <SidebarItem
                                key={item.id}
                                item={item}
                                selectedItemId={activeItem}
                                handleSideBarClick={handleSideBarClick}
                            />
                        ))}
                    </div>
                    {location.pathname.includes('parker') && (
                        <div className="sidebar-bottom">
                            {bottomItems.map((item) => (
                                <SidebarItem
                                    key={item.id}
                                    item={item}
                                    selectedItemId={activeItem}
                                    handleSideBarClick={handleSideBarClick}
                                />
                            ))}
                            <div className="sidebar-profile" onClick={() => handleSideBarClick({ name: 'Logout' })}>
                                <div className="profile-icon">
                                    {userInfo?.userIcon ? (
                                        <img src={userInfo?.userIcon} alt="User Icon" />
                                    ) : (
                                        <img src={userCircle} alt="user-circle" />
                                    )}
                                    {!collapsedForm && <span className="profile-name">{userInfo?.name}</span>}
                                </div>
                                {!collapsedForm && (
                                    <div className="profile-info">
                                        <img src={redLogout} alt="Logout" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <LogoutModal isOpen={showLogoutModal} handleModal={handleLogoutModal} />
                </div>
            )}
        </>
    );
};
export default Sidebar;
