import calendar from '@icons/calendar.svg';

const CustomInput = ({ openCalendar, value }) => {
    return (
        <div
            onClick={openCalendar}
            className="custom-input"
            style={{
                ...customInputStyle,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <span>{value.includes('~') ? value : 'Select Date Range'}</span>
            <img src={calendar} alt="calendar-icon" style={{ width: 16, height: 16, marginLeft: 8 }} />
        </div>
    );
};

const customInputStyle = {
    border: '1px solid #AEADAD',
    borderRadius: '6px',
    padding: '8px 12px',
    cursor: 'pointer',
    minWidth: 208,
    maxWidth: 208,
    color: 'grey',
    boxSizing: 'border-box',
    fontSize: '13px',
    minHeight: '48px'
};

export default CustomInput;
