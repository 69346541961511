import { useEffect, useState } from 'react';
import closeIcon from '@icons/close-icon-grey.svg';
import { Col, Modal, Row, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axiosWrapper from '../../../utils/api';
import { API_URL } from '../../../utils/apiUrl';
import './SubcriptionDetailModal.scss';
import { useSelector } from 'react-redux';
import Map, { Marker } from 'react-map-gl';
import redMarker from '@icons/redMarker.svg';
import 'mapbox-gl/dist/mapbox-gl.css';
import { toast } from 'react-toastify';
import { formatDays, formatTimeRange } from '../../../utils/common';

const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

const SubcriptionDetailModal = ({ show, handleClose, selectedLocationId }) => {
    const navigate = useNavigate();
    const { userToken } = useSelector((state) => state.auth);

    const [locationDetails, setLocationDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedRate, setSelectedRate] = useState(null); // Track selected recurring rate

    useEffect(() => {
        if (show && selectedLocationId) {
            fetchLocationDetails(selectedLocationId);
        }
    }, [show, selectedLocationId]);

    const fetchLocationDetails = async (locationId) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosWrapper(
                'GET',
                `${API_URL.GET_SINGLE_AVAILABLE_LOCATION(locationId)}`,
                {},
                userToken
            );
            if (response?.data) {
                setLocationDetails(response.data);
            } else {
                setError('No data found.');
            }
        } catch (error) {
            setError('Failed to fetch location details. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Once locationDetails are fetched, if we have publicRecurringRates, select the first one by default
        if (locationDetails && locationDetails.recurringRates) {
            const publicRecurringRates = locationDetails.recurringRates.filter((rate) => rate.isPublic) || [];
            if (publicRecurringRates.length > 0 && !selectedRate) {
                setSelectedRate(publicRecurringRates[0]);
            }
        }
    }, [locationDetails, selectedRate]);

    const handleRateSelection = (rate) => {
        setSelectedRate(rate); // Set the selected recurring rate
    };

    const proceedToCheckout = () => {
        if (!selectedRate) {
            toast.error('Please select a subscription plan.');
            return;
        }
        navigate('/parker/manage-subscription', {
            state: {
                locationId: locationDetails?._id,
                selectedRate: selectedRate // Pass selected rate to checkout
            }
        });
    };

    if (!show) {
        return null;
    }

    // Filter only public recurring rates
    const publicRecurringRates = locationDetails?.recurringRates?.filter((rate) => rate.isPublic) || [];

    return (
        <Modal show={show} onHide={handleClose} className="subscription-modal" size="lg" centered>
            <Modal.Header>
                <div className="cross-icon w-100 p-3">
                    <img
                        className="close-button"
                        width={30}
                        src={closeIcon}
                        alt="close icon"
                        onClick={handleClose}
                        role="button"
                        aria-label="Close"
                    />
                </div>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                        <Spinner animation="border" />
                    </div>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : locationDetails ? (
                    <>
                        <div className="session-title">
                            <h4>Subscription Details</h4>
                        </div>

                        <Row className="content-tiles mt-4">
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <h5>{locationDetails.ownerName || 'N/A'}</h5>
                                <p>{locationDetails.address || 'Address not available'}</p>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} className="pricing-tax mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                {publicRecurringRates.length > 0 && selectedRate ? (
                                    <>
                                        <h6>
                                            ${selectedRate.rate}/{selectedRate.frequency.toLowerCase()}
                                        </h6>
                                        <span>*Additional fees and taxes may apply</span>
                                    </>
                                ) : (
                                    <h6>Pricing information not available</h6>
                                )}
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            {publicRecurringRates.length > 0 ? (
                                publicRecurringRates.map((item) => (
                                    <Col xl={4} lg={6} md={6} sm={12} className="mb-4" key={item._id}>
                                        <div
                                            className={`session-content ${
                                                selectedRate?._id === item._id ? 'selected-plan' : ''
                                            }`}
                                            onClick={() => handleRateSelection(item)}
                                            role="button"
                                            tabIndex={0}
                                            onKeyDown={(e) => e.key === 'Enter' && handleRateSelection(item)}
                                        >
                                            <Row>
                                                <Col lg={6} xs={6} sm={6} className="mb-2">
                                                    <h4>{item.rateName || 'N/A'}</h4>
                                                </Col>
                                                <Col lg={6} xs={6} sm={6} className="placement mb-2">
                                                    <h4>${item.rate}</h4>
                                                </Col>
                                                <Col lg={6} xs={6} sm={6} className="mb-2">
                                                    <p>Frequency</p>
                                                </Col>
                                                <Col lg={6} xs={6} sm={6} className="placement mb-2">
                                                    <span>{item.frequency}</span>
                                                </Col>
                                                <Col lg={6} xs={6} sm={6} className="mb-2">
                                                    <p>Parking Days</p>
                                                </Col>
                                                <Col lg={6} xs={6} sm={6} className="placement mb-2">
                                                    <span> {formatDays(item.validParkingDays)}</span>
                                                </Col>
                                                <Col lg={6} xs={6} sm={6} className="mb-2">
                                                    <p>Time Range</p>
                                                </Col>
                                                <Col lg={6} xs={6} sm={6} className="placement mb-2">
                                                    <span>
                                                        {formatTimeRange(
                                                            item.validParkingTime.startTime,
                                                            item.validParkingTime.endTime
                                                        )}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row className="mt-3">
                                                <Col className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                                    <button
                                                        className={`manage-btn ${
                                                            selectedRate?._id === item._id ? 'selectedPlan' : ''
                                                        }`}
                                                        disabled={selectedRate?._id === item._id}
                                                        type="button"
                                                    >
                                                        {selectedRate?._id === item._id ? 'Selected' : 'Select'}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                ))
                            ) : (
                                <Col>
                                    <Alert variant="warning">
                                        No available subscription plans for checkout. Please contact support for more
                                        information.
                                    </Alert>
                                </Col>
                            )}
                        </Row>

                        {publicRecurringRates.length > 0 && (
                            <>
                                <div className="map-container">
                                    {locationDetails.latitude && locationDetails.longitude ? (
                                        <Map
                                            initialViewState={{
                                                longitude: locationDetails.longitude,
                                                latitude: locationDetails.latitude,
                                                zoom: 12
                                            }}
                                            style={{ width: '100%', height: '400px' }}
                                            mapStyle="mapbox://styles/mapbox/streets-v11"
                                            mapboxAccessToken={MAPBOX_TOKEN}
                                        >
                                            <Marker
                                                longitude={locationDetails.longitude}
                                                latitude={locationDetails.latitude}
                                                anchor="center"
                                            >
                                                <img src={redMarker} alt="Marker" className="custom-marker" />
                                            </Marker>
                                        </Map>
                                    ) : (
                                        <p>Location map not available.</p>
                                    )}
                                </div>

                                <div className="mt-4 mb-4 d-flex justify-content-end">
                                    <button
                                        className="checkout-btn"
                                        type="button"
                                        onClick={proceedToCheckout}
                                        disabled={!selectedRate || loading}
                                    >
                                        Checkout
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <p>No data available.</p>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default SubcriptionDetailModal;
