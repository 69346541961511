import { useDispatch, useSelector } from 'react-redux';
import { changeCollapsedForm, changeHiddenForm } from '@redux/sidebar/sidebar_slice';
import logo from '@icons/white-logo.svg';
import menuIcon from '@icons/menu-icon.svg';
import './MobileTopBar.scss';

const MobileTopBar = () => {
    const dispatch = useDispatch();
    const { transitionType, collapsedForm, hiddenForm } = useSelector((state) => state.sidebar);
    return (
        <div
            className={`mobile-topbar ${window.location.pathname === '/parker/' || window.location.pathname === '/parker' ? 'mobile-topbar-min-width height-expand' : ''}`}
        >
            {transitionType === 'collapse/expand' && (
                <div className="topbar-left-items">
                    {/* <img className={`logo ${collapsedForm ? 'hide-logo' : 'show-logo'}`} src={logo} alt="brand-logo" /> */}
                    <img
                        src={menuIcon}
                        className={`menu-toggler ${collapsedForm ? 'remove-margin' : 'add-margin'}`}
                        onClick={() => dispatch(changeCollapsedForm(!collapsedForm))}
                    />
                </div>
            )}
            {transitionType === 'hide/show' && (
                <div className="topbar-left-items">
                    <img
                        src={menuIcon}
                        className={'menu-toggler remove-margin'}
                        onClick={() => dispatch(changeHiddenForm(!hiddenForm))}
                    />
                </div>
            )}

            <div className="d-flex justify-content-center w-100">
                <img src={logo} alt="img" />
            </div>
        </div>
    );
};

export default MobileTopBar;
