import { Breadcrumb, Col, Row } from 'react-bootstrap';
import './ViewParkers.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API_URL } from '../../utils/apiUrl';
import axiosWrapper from '../../utils/api';
import { useSelector } from 'react-redux';
import Loading from '../Loading/Loading';
import { format } from 'date-fns';

const ViewParkers = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Retrieve the "id" parameter from the URL

    const [parker, setParker] = useState(null);
    const [loading, setLoading] = useState(false);

    const { userToken } = useSelector((state) => state.auth);

    const fetchParker = async () => {
        setLoading(true);
        try {
            const response = await axiosWrapper('GET', `${API_URL.GET_PARKER_BY_ID}/${id}`, null, userToken);
            setParker(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParker();
    }, [id]);

    return (
        <>
            <div className="breadcrumb-parker">
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate('/admin/parkers')}>Parkers</Breadcrumb.Item>
                    <Breadcrumb.Item active>View Parker</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {loading ? (
                <Loading centered fulWidth />
            ) : (
                <div className="view-parkers">
                    <div className="heading-section">Profile ID</div>
                    <Row className="mt-4">
                        <Col xs={12} sm={12} md={4}>
                            <h4 className="title">Name</h4>
                            <p className="text-data">{parker?.name || '--'}</p>
                        </Col>
                        <Col xs={12} sm={12} md={4} className="mt-3 mt-md-0 mt-lg-0">
                            <h4 className="title">Email</h4>
                            <p className="text-data">{parker?.email || '--'}</p>
                        </Col>
                        <Col xs={12} sm={12} md={4} className="mt-3 mt-md-0 mt-lg-0">
                            <h4 className="title">Phone No.</h4>
                            <p className="text-data">{parker?.phoneNumber}</p>
                        </Col>
                    </Row>
                    <div className="heading-section mt-4">Vehicles Detail</div>
                    {parker?.vehicles.length > 0 ? (
                        parker?.vehicles?.map((item, id) => (
                            <Row key={item.no} className="mt-4">
                                <Col xs={6} sm={6} md={3} className="d-flex">
                                    <div className="count-section">
                                        <h4 className="title">No.</h4>
                                        <p className="text-data">{id + 1}</p>
                                    </div>
                                    <div className="name-section">
                                        <h4 className="title">Vehicle Name</h4>
                                        <p className="text-data">{item.vehicleMake}</p>
                                    </div>
                                </Col>
                                <Col xs={6} sm={6} md={3}>
                                    <h4 className="title">Car Plate No</h4>
                                    <p className="text-data">{item.licensePlate}</p>
                                </Col>
                                <Col xs={6} sm={6} md={3} className="mt-3 mt-md-0 mt-lg-0">
                                    <h4 className="title">Province/State</h4>
                                    <p className="text-data">{item.province}</p>
                                </Col>
                                <Col xs={6} sm={6} md={3} className="mt-3 mt-md-0 mt-lg-0">
                                    <h4 className="title">Created Date</h4>
                                    <p className="text-data">{format(item.createdAt, 'yyyy-MM-dd')}</p>
                                </Col>
                            </Row>
                        ))
                    ) : (
                        <p className="text-center my-3">No vehicles found.</p>
                    )}

                    <div className="heading-section mt-4">Subscriptions</div>
                    {parker?.subscriptions?.length > 0 ? (
                        parker?.subscriptions.map((sub) => (
                            <Row key={sub._id} className="mb-4">
                                <Col lg={6} md={6}>
                                    <Row className="mt-3">
                                        <Col>
                                            <h4 className="title">Plan Name</h4>
                                        </Col>
                                        <Col>
                                            <p className="text-area">{sub?.name || 'N/A'}</p>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <h4 className="title">Amount</h4>
                                        </Col>
                                        <Col>
                                            <p className="text-area">
                                                {sub.amount
                                                    ? `${(sub?.amount / 100).toFixed(2)} ${sub?.currency || 'USD'}`
                                                    : 'N/A'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <h4 className="title">Frequency</h4>
                                        </Col>
                                        <Col>
                                            <p className="text-area">{sub?.frequency || 'N/A'}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6}>
                                    <Row className="mt-3">
                                        <Col>
                                            <h4 className="title">Status</h4>
                                        </Col>
                                        <Col>
                                            <p
                                                className={`text-area ${sub?.status === 'ACTIVE' ? 'text-success' : 'text-danger'}`}
                                            >
                                                {sub?.status}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <h4 className="title">Start Date</h4>
                                        </Col>
                                        <Col>
                                            <p className="text-area">
                                                {sub.startDate
                                                    ? new Date(sub?.startDate).toLocaleDateString('en-US')
                                                    : 'N/A'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <h4 className="title">Next Billing Date</h4>
                                        </Col>
                                        <Col>
                                            <p className="text-area">
                                                {sub.nextBillingDate
                                                    ? new Date(sub?.nextBillingDate).toLocaleDateString('en-US')
                                                    : 'N/A'}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))
                    ) : (
                        <p className="text-center my-3">No subscriptions available for this user.</p>
                    )}
                </div>
            )}
        </>
    );
};

export default ViewParkers;
