export const provinceOptions = [
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Alaska', label: 'Alaska' }
    // Add more provinces as needed
];

export const modelOptions = [
    { value: 'Honda', label: 'Honda' },
    { value: 'Toyota', label: 'Toyota' }
    // Add more models as needed
];

export const yearOptions = Array.from({ length: 30 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { value: year.toString(), label: year.toString() };
});

export const colorOptions = [
    { value: 'Red', label: 'Red' },
    { value: 'Blue', label: 'Blue' }
    // Add more colors as needed
];
