import { useEffect, useState } from 'react';
import { Breadcrumb, Col, Row, Spinner } from 'react-bootstrap';
import './ManageSubscription.scss';
import axiosWrapper from '../../../utils/api';
import { API_URL } from '../../../utils/apiUrl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { toast } from 'react-toastify';
import Select from 'react-select';
import SelectPaymentMethod from '../../SelectPaymentMethod/SelectPaymentMethod';
import { useMediaQuery } from 'react-responsive';
import plus from '@icons/GreenPlus.svg';

const ManageSubscription = () => {
    const navigate = useNavigate();
    const { userInfo, userToken } = useSelector((state) => state.auth);
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    const [locationDetails, setLocationDetails] = useState(null);
    const location = useLocation();
    const { locationId, selectedRate } = location.state || '';
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);

    // Uplifted state for cards
    const [cards, setCards] = useState([]);
    const [defaultCardId, setDefaultCardId] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);

    const [isAddingCard, setIsAddingCard] = useState(false);

    // For delete modal
    const [showModal, setShowModal] = useState(false);
    const [cardToDelete, setCardToDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

    useEffect(() => {
        if (locationId) {
            fetchLocationDetails(locationId);
            fetchUserVehicles();
            fetchCards();
            fetchDefaultCard();
        }
    }, [locationId]);

    const fetchLocationDetails = async (locationId) => {
        const response = await axiosWrapper(
            'GET',
            `${API_URL.GET_SINGLE_AVAILABLE_LOCATION(locationId)}`,
            {},
            userToken
        );
        if (response?.data) {
            setLocationDetails(response.data);
        }
    };

    const fetchUserVehicles = async () => {
        const response = await axiosWrapper('GET', `${API_URL.GET_VEHICLES}`, {}, userToken);
        if (response?.data) {
            setVehicles(response.data?.vehicles);
            const options = response.data?.vehicles.map((vehicle) => ({
                value: vehicle?._id,
                label: `${vehicle.vehicleMake}`
            }));
            setVehicleOptions(options);
        }
    };

    const fetchCards = async () => {
        const response = await axiosWrapper('GET', `${API_URL.GET_CARDS}?email=${userInfo?.email}`, {}, userToken);
        setCards(response?.data || []);
    };

    const fetchDefaultCard = async () => {
        const response = await axiosWrapper(
            'GET',
            `${API_URL.GET_DEFAULT_CARD}?email=${userInfo?.email}`,
            {},
            userToken
        );
        if (response?.data) {
            setDefaultCardId(response.data.id);
            setSelectedCard(response.data.id);
        }
    };

    const handleVehicleSelection = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
        if (selectedValues.length > 3) {
            toast.error('You can select up to 3 vehicles.');
            return;
        }
        setSelectedVehicles(selectedValues);
    };

    const isPaymentDisabled = selectedVehicles.length < 1 || !selectedCard;

    const onSetDefaultCard = async (cardId) => {
        const response = await axiosWrapper(
            'POST',
            API_URL.SET_DEFAULT_CARD,
            { email: userInfo?.email, cardId },
            userToken
        );
        setDefaultCardId(response?.data?.defaultCardId);
        setSelectedCard(response?.data?.defaultCardId);
        fetchCards();
    };

    const onDeleteCard = (cardId) => {
        setCardToDelete(cardId);
        setShowModal(true);
    };

    const confirmDelete = async () => {
        if (!cardToDelete) return;
        setIsLoading(true);
        try {
            await axiosWrapper('DELETE', `${API_URL.DELETE_CARD(cardToDelete)}`, { email: userInfo?.email }, userToken);
            setCards((prevCards) => prevCards.filter((card) => card.id !== cardToDelete));

            if (cardToDelete === defaultCardId) {
                // If the deleted card was the default, handle accordingly
                if (cards.length > 0) {
                    const remainingCards = cards.filter((card) => card.id !== cardToDelete);
                    if (remainingCards.length > 0) {
                        await onSetDefaultCard(remainingCards[0].id);
                    } else {
                        setDefaultCardId(null);
                        setSelectedCard(null);
                    }
                } else {
                    setDefaultCardId(null);
                    setSelectedCard(null);
                }
            }

            setShowModal(false);
            setCardToDelete(null);
            setIsLoading(false);
        } catch (error) {
            setShowModal(false);
            setCardToDelete(null);
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCardToDelete(null);
    };

    const handleCardTokenize = async (token) => {
        if (!selectedVehicles.length) {
            toast.error('Please select at least one vehicle before adding a card.');
            return;
        }

        try {
            // Add new card and associate with vehicles
            await axiosWrapper(
                'POST',
                `${API_URL.ADD_CARD}`,
                {
                    sourceId: token.token,
                    email: userInfo.email,
                    vehicles: selectedVehicles
                },
                userToken
            );

            setIsAddingCard(false);
            await fetchCards();
            // You can set the newly added card as selectedCard if desired
        } catch (error) {
            setIsAddingCard(false);
        }
    };

    const handlePayment = async () => {
        try {
            setIsPaymentProcessing(true);

            await axiosWrapper(
                'POST',
                `${API_URL.CREATE_SUBSCRIPTION}`,
                {
                    email: userInfo?.email,
                    cardId: selectedCard,
                    locationId: locationId,
                    selectedRateId: selectedRate?._id,
                    vehicleIds: selectedVehicles
                },
                userToken
            );
            navigate('/parker/subscriptions');
            setIsPaymentProcessing(false);
        } catch (error) {
            setIsPaymentProcessing(false);
        }
    };

    return (
        <>
            <div className="breadcrumb-vehicles">
                <Breadcrumb>
                    <Breadcrumb.Item
                        onClick={() =>
                            navigate('/parker/session-details', { state: { activeTab: 'activeSubscriptions' } })
                        }
                    >
                        Subscriptions
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Payment</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="payment-modal">
                <div className="session-title">
                    <h4>Checkout</h4>
                </div>
                <Row className="mt-4">
                    <Col xs={12} sm={12} md={12} lg={6}>
                        {isMobile && <p>Select Payment Method</p>}
                        <SelectPaymentMethod
                            isMobile={isMobile}
                            cards={cards}
                            selectedCard={selectedCard}
                            defaultCardId={defaultCardId}
                            onSelectCard={setSelectedCard}
                            onSetDefaultCard={onSetDefaultCard}
                            onDeleteCard={onDeleteCard}
                            showModal={showModal}
                            handleCloseModal={handleCloseModal}
                            confirmDelete={confirmDelete}
                            cardToDelete={cardToDelete}
                            isLoading={isLoading}
                        />

                        <div className="vehicle-select mt-4">
                            <h6>Assign up to 3 vehicles</h6>
                            <p>Only one vehicle may park at a time</p>

                            <div className="select-vehicle mt-2">
                                <Select
                                    isMulti
                                    name="vehicles"
                                    options={vehicleOptions}
                                    className="single-select-field"
                                    classNamePrefix="__select-days"
                                    value={vehicleOptions.filter((option) => selectedVehicles.includes(option.value))}
                                    onChange={handleVehicleSelection}
                                />
                            </div>
                            {isPaymentDisabled && (
                                <p className="text-danger mt-2">
                                    Please select at least one vehicle and a payment method before proceeding.
                                </p>
                            )}

                            <div className="mt-4 mb-4">
                                {!isAddingCard ? (
                                    <div className="add-card-btn" onClick={() => setIsAddingCard(true)}>
                                        <span>Add New Card</span>
                                        <img src={plus} alt="Plus sign" />
                                    </div>
                                ) : (
                                    <div className="card-form-container">
                                        <PaymentForm
                                            applicationId={import.meta.env.VITE_APPLICATION_ID}
                                            locationId={import.meta.env.VITE_LOCATION_ID}
                                            cardTokenizeResponseReceived={(token) => handleCardTokenize(token)}
                                            createPaymentRequest={() => ({
                                                countryCode: 'US',
                                                currencyCode: 'USD',
                                                total: {
                                                    amount: '1.00', // Temporary amount for card validation
                                                    label: 'Card Validation'
                                                }
                                            })}
                                        >
                                            <CreditCard
                                                buttonProps={{
                                                    css: {
                                                        backgroundColor: selectedVehicles.length ? '#1FA846' : '#ccc',
                                                        cursor: selectedVehicles.length ? 'pointer' : 'not-allowed',
                                                        color: '#fff',
                                                        width: '100%',
                                                        fontSize: '14px',

                                                        '&:hover': {
                                                            backgroundColor: selectedVehicles.length
                                                                ? '#1C9442'
                                                                : '#ccc'
                                                        }
                                                    }
                                                }}
                                            >
                                                Add Card
                                            </CreditCard>
                                        </PaymentForm>
                                        <button
                                            type="button"
                                            className="btn btn-secondary mt-3"
                                            onClick={() => setIsAddingCard(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={6} className="mt-3 mt-lg-0">
                        <h5>Total</h5>
                        <div className="total-amount mt-3">
                            <div>
                                <Row>
                                    <Col>
                                        <h4>Zone</h4>
                                    </Col>
                                    <Col className="end-content">
                                        <h4>{locationDetails?.zone || 'N/A'}</h4>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col lg={8} xs={9}>
                                        <h4>{locationDetails?.addressType || 'N/A'}</h4>
                                        <p>
                                            {locationDetails?.address || 'Address not available'},{' '}
                                            {locationDetails?.city || 'N/A'}, {locationDetails?.state || 'N/A'}
                                        </p>
                                    </Col>
                                    <Col lg={4} xs={3} className="end-content">
                                        <h4>
                                            ${selectedRate?.rate || 'N/A'}/
                                            {selectedRate?.frequency?.toLowerCase() || 'N/A'}
                                        </h4>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col lg={6} xs={12}>
                                        <h4>Rate Name</h4>
                                        <p>{selectedRate?.rateName || 'N/A'}</p>
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <div className="d-flex justify-content-end">
                                            <h4>Frequency</h4>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <p>{selectedRate?.frequency || 'N/A'}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col lg={6} xs={12}>
                                        <h4>Valid Parking Days</h4>
                                        <p>{selectedRate?.validParkingDays?.join(', ') || 'N/A'}</p>
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <div className="d-flex justify-content-end">
                                            <h4>Valid Parking Time</h4>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <p>
                                                {selectedRate?.validParkingTime?.startTime || 'N/A'} -{' '}
                                                {selectedRate?.validParkingTime?.endTime || 'N/A'}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col lg={8} xs={9}>
                                        <h4>Total</h4>
                                    </Col>
                                    <Col lg={4} xs={3} className="end-content">
                                        <h4>${selectedRate?.rate || 'N/A'}</h4>
                                    </Col>
                                </Row>
                                <div className="mt-5">
                                    <div className="add-btn">
                                        <button
                                            onClick={handlePayment}
                                            disabled={isPaymentDisabled || isPaymentProcessing}
                                            type="button"
                                        >
                                            {isPaymentProcessing ? (
                                                <Spinner animation="border" size="sm" />
                                            ) : (
                                                `Pay: $${selectedRate?.rate.toFixed(2)} ${selectedRate?.frequency}`
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ManageSubscription;
