import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './Parkers.scss';
import searchNormal from '@icons/search-icon.svg';
import DatePicker from 'react-multi-date-picker';
import CustomInput from '../../components/TransactionsFilters/DateFilterCustom';
import Footer from 'react-multi-date-picker/plugins/range_picker_footer';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import eyeIcon from '@icons/view-icon.svg';
import editicon from '@icons/edit-gray.svg';
import trashIcon from '@icons/trash-icon.svg';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../utils/apiUrl';
import axiosWrapper from '../../utils/api';
import { useSelector } from 'react-redux';
import DeleteModal from '../../components/Modals/DeleteModal/DeleteModal';
import { formatDate } from '../../utils/common';
import Loading from '../../components/Loading/Loading';

const Parkers = () => {
    const [dateValue, setDateValue] = useState([]);
    const [parkers, setParkers] = useState([]);
    const [loading, setLoading] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const { userToken } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [gridApi, setGridApi] = useState(null);

    const tableActionButtons = (row) => (
        <div className="d-flex">
            <div className="actions-cursor" onClick={() => navigate(`/admin/parkers/${row?.data?._id}`)}>
                <img src={eyeIcon} alt="View icon" />
            </div>
            <div className="actions-cursor ms-3" onClick={() => navigate(`/admin/edit-parker/${row?.data?._id}`)}>
                <img src={editicon} alt="Edit icon" />
            </div>
            <div className="actions-cursor ms-3">
                <img src={trashIcon} alt="Trash icon" onClick={() => handleDeleteModal(row?.data?._id)} />
            </div>
        </div>
    );

    const handleDeleteModal = (id) => {
        setShowDeleteModal(!showDeleteModal);
        setSelectedRow(id);
    };

    const [colDefs] = useState([
        {
            field: 'Id',
            headerName: 'ID',
            width: 120,
            valueGetter: (params) => params.node.rowIndex + 1,
            filter: true // Enable filter on this column
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 140,
            filter: 'agTextColumnFilter',
            valueFormatter: (params) => (params.value ? params.value : '--')
        },
        { field: 'phoneNumber', headerName: 'Phone', width: 170, filter: 'agTextColumnFilter' },
        {
            field: 'email',
            headerName: 'Email',
            width: 220,
            filter: 'agTextColumnFilter',
            valueFormatter: (params) => (params.value ? params.value : '--')
        },
        { field: 'sessions', headerName: 'Sessions', width: 120, filter: 'agNumberColumnFilter' },
        { field: 'subscriptions', headerName: 'Subscription', width: 190, filter: 'agNumberColumnFilter' },
        {
            field: 'createdAt',
            headerName: 'Created Date',
            width: 150,
            valueFormatter: (params) => formatDate(params.value),
            filter: 'agDateColumnFilter'
        },
        { field: 'actions', cellRenderer: tableActionButtons, filter: false } // No filter needed for actions
    ]);

    const fetchParkers = async () => {
        setLoading(true);
        try {
            // Extract fromDate and toDate if available
            let fromDate = '';
            let toDate = '';
            if (dateValue && dateValue.length === 2) {
                // Convert selected dates to ISO string or YYYY-MM-DD as required by backend
                fromDate = dateValue[0].format('YYYY-MM-DD');
                toDate = dateValue[1].format('YYYY-MM-DD');
            }

            const queryParams = new URLSearchParams();
            if (fromDate) queryParams.set('fromDate', fromDate);
            if (toDate) queryParams.set('toDate', toDate);

            const url = `${API_URL.GET_ALL_PARKERS}?${queryParams.toString()}`;

            const response = await axiosWrapper('GET', url, null, userToken);
            setParkers(response.data);
        } catch (error) {
            setParkers([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParkers();
    }, []);

    useEffect(() => {
        fetchParkers();
    }, [dateValue]);

    const onConfirmDelete = async () => {
        try {
            setDeleteLoading(true);
            await axiosWrapper('delete', `${API_URL.DELETE_PARKER}/${selectedRow}`, null, userToken);
            fetchParkers();
            setSelectedRow(null);
        } catch (error) {
            // Handle error if needed
        } finally {
            setDeleteLoading(false);
            setShowDeleteModal(false);
        }
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        if (loading) {
            params.api.showLoadingOverlay();
        }
    };

    const onSearchChange = (e) => {
        const value = e.target.value;
        if (gridApi) {
            gridApi.setQuickFilter(value); // Apply the quick filter to the grid
        }
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(parkers);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Parkers');
        XLSX.writeFile(workbook, 'Parkers.xlsx');
    };

    const gridOptions = {
        overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
        overlayNoRowsTemplate: '<span>No data found.</span>'
    };

    return (
        <div className="parkers">
            <Row className="align-items-center">
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <h4>Parkers</h4>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={4} className="d-flex justify-content-end mt-3 mt-xl-0">
                    <div className="right-items">
                        <div className="search-input-container">
                            <input type="text" name="search" placeholder="Search" onChange={onSearchChange} />
                            <img src={searchNormal} alt="Search icon" className="search-icon" />
                        </div>
                        <div className="me-3 date-filter responsive-date-picker">
                            <DatePicker
                                render={<CustomInput />}
                                value={dateValue}
                                onChange={setDateValue}
                                range
                                numberOfMonths={2}
                                placeholder="Select Date Range"
                                rangeHover
                                plugins={[<Footer key={1} position="bottom" />]}
                                calendarPosition="bottom-right"
                            />
                        </div>

                        <div className="add-location-btn" onClick={exportToExcel}>
                            Export to Excel
                        </div>
                    </div>
                </Col>
            </Row>
            <main className="transactions-table-container mt-4">
                <div className="ag-theme-quartz" style={{ height: 500 }}>
                    {loading ? (
                        <div className="loading-container">
                            <Loading />
                        </div>
                    ) : (
                        <AgGridReact
                            pagination={true}
                            paginationAutoPageSize={true}
                            suppressRowHoverHighlight={true}
                            rowData={parkers}
                            gridOptions={gridOptions}
                            columnDefs={colDefs}
                            scrollbarWidth={20}
                            onGridReady={onGridReady}
                        />
                    )}
                </div>
            </main>
            <DeleteModal
                isOpen={showDeleteModal}
                handleClose={handleDeleteModal}
                handleConfirm={onConfirmDelete}
                title={'Delete Parker'}
                loading={deleteLoading}
                body={'Are you sure that you want to delete this Parker?'}
            />
        </div>
    );
};

export default Parkers;
