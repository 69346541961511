import { useEffect, useState } from 'react';
import searchNormal from '@icons/search-icon.svg';
import './SessionDetails.scss';
import { Breadcrumb, Col, Row, Tab, Tabs } from 'react-bootstrap';
import Sessions from './Sessions/Sessions';
import ActiveSubscriptions from './ActiveSubscriptions/ActiveSubscriptions';
import PreviousSubscriptions from './PreviousSubscriptions/PreviousSubscriptions';
// import PaymentsTab from './PaymentsTab/PaymentsTab';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { getAllPayments } from '../../context/payment/getPaginatedPaymentsInfo';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const SessionDetails = () => {
    const [search, setSearch] = useState('');
    const { userInfo } = useSelector((state) => state.auth);
    const location = useLocation();
    const [key, setKey] = useState(location.state?.activeTab || 'Sessions');
    const navigate = useNavigate();
    const [allSessions, setAllSession] = useState([]);
    // const [selectedPlatform, setSelectedPlatform] = useState('All');

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    // const defaultOptions = [
    //     { value: 'All', label: 'All' },
    //     { value: 'Android', label: 'Android' },
    //     { value: 'IOS', label: 'IOS' }
    // ];
    // const handlePlatformChange = (selectedOption) => {
    //     setSelectedPlatform(selectedOption.value);
    // };

    const [filteredSessions, setFilteredSessions] = useState([]);

    const getRecentSessions = async () => {
        try {
            const transitionsData = await getAllPayments();
            if (!transitionsData.status) {
                toast.error('Unable to fetch transactions');
            } else {
                const sessions = transitionsData.data?.data
                    ?.filter(
                        (item) =>
                            item?.phoneNumber.replace(/[^\d+]/g, '') === userInfo?.phoneNumber ||
                            item?.email === userInfo?.email
                    )
                    ?.map((item, index) => {
                        let totalTime = '0m';
                        if (item?.paymentData) {
                            const duration = item?.paymentData?.duration;
                            totalTime = duration?.byHour
                                ? `${duration?.value?.hours}:h ${duration?.value?.minutes}:m `
                                : `${duration?.value?.days > 1 ? duration?.value?.days + ' Days' : duration?.value?.days + ' Day'}`;
                        }
                        return {
                            id: index,
                            zone: item?.paymentData?.zone?.value || '',
                            plateNo: item?.paymentData?.vehicle?.value?.licensePlatNumber || '',
                            date: format(item.createdAt, 'yyyy-MM-dd'),
                            totalTime: totalTime,
                            status: item?.status === 'COMPLETED'
                        };
                    });
                setAllSession(sessions || []);
                setFilteredSessions(sessions || []);
            }
        } catch (error) {
            toast.error('Failed to fetch transactions');
        }
    };

    useEffect(() => {
        getRecentSessions();
    }, []);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(e.target.value);
        const filteredSessionsAll = allSessions.filter(
            (session) =>
                session.plateNo.toLowerCase().includes(value?.toLowerCase()) ||
                session.zone.toLowerCase().includes(value?.toLowerCase())
        );
        setFilteredSessions(filteredSessionsAll);
    };

    return (
        <div className="session-details">
            {isMobile && (
                <div className="breadcrumb-custom mb-4">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/parker')}>Recent Sessions</Breadcrumb.Item>
                        <Breadcrumb.Item active>Session Details</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}

            <Row className="align-items-center">
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <h4>Activity</h4>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} xl={8} className="d-flex justify-content-end mt-3 mt-xl-0">
                    <div className="right-items">
                        <div className="search-input-container">
                            <input
                                onChange={(e) => handleSearchChange(e)}
                                value={search}
                                type="text"
                                name="search"
                                placeholder="Search"
                            />
                            <img src={searchNormal} alt="Search icon" className="search-icon" />
                        </div>
                        {/* <div className="dropdown-container">
                            <DropdownFilter
                                options={defaultOptions}
                                selectedOptions={selectedPlatform}
                                onChange={handlePlatformChange}
                                title={'Filter by City'}
                            />
                        </div> */}
                    </div>
                </Col>
            </Row>
            <div className="tabs-header mt-4">
                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="">
                    <Tab eventKey="Sessions" title="Sessions">
                        <Sessions sessionData={filteredSessions} />
                    </Tab>
                    <Tab eventKey="activeSubscriptions" title="Active Subscriptions">
                        <ActiveSubscriptions activeKey={key} />
                    </Tab>
                    <Tab eventKey="previousSubscriptions" title="Previous Subscriptions">
                        <PreviousSubscriptions />
                    </Tab>
                    {/* <Tab eventKey="Payments" title="Payments">
                        <PaymentsTab />
                    </Tab> */}
                </Tabs>
            </div>
        </div>
    );
};

export default SessionDetails;
