import { useEffect, useState } from 'react';
import './PreviousSubscriptions.scss';
import { Col, Row } from 'react-bootstrap';
import axiosWrapper from '../../../utils/api';
import { API_URL } from '../../../utils/apiUrl';
import { useSelector } from 'react-redux';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import redMarker from '@icons/redMarker.svg';
import { useNavigate } from 'react-router-dom';
import SubcriptionDetailModal from '../../Modals/SubcriptionDetailModal/SubcriptionDetailModal';

const PreviousSubscriptions = ({ searchQuery, dateRange, key = '' }) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const { userToken } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
    const subscriptionChange = useSelector((state) => state.root.subscriptionChange);

    useEffect(() => {
        fetchPreviousSubscriptions();
    }, [searchQuery, dateRange, subscriptionChange]);

    const fetchPreviousSubscriptions = async () => {
        const fromDate = dateRange?.[0] ? dateRange[0].format('YYYY-MM-DD') : '';
        const toDate = dateRange?.[1] ? dateRange[1].format('YYYY-MM-DD') : '';

        const params = new URLSearchParams();
        if (searchQuery) params.set('search', searchQuery);
        if (fromDate && toDate) {
            params.set('fromDate', fromDate);
            params.set('toDate', toDate);
        }

        const url = `${API_URL.GET_PREVIOUS_SUBSCRIPTIONS}?${params.toString()}`;
        try {
            const response = await axiosWrapper('GET', url, {}, userToken);
            setSubscriptions(response.data);
        } catch (error) {
            // Handle error
        }
    };
    const handleClose = () => setShow(false);
    const handleSubscribe = (item) => {
        setShow(true);
        setSelectedLocationId(item._id); // Use the unique identifier of the location
    };

    return (
        <div className="previous-content">
            <div className="session-title mt-4">
                <h4>Previous Subscriptions</h4>
            </div>

            <Row className="mt-4">
                {subscriptions?.length === 0 ? (
                    <p className="text-center">No previous subscriptions found.</p>
                ) : (
                    subscriptions?.map((item) => (
                        <Col key={item._id} xs={12} sm={12} md={6} lg={4} className="mb-4">
                            <div className="active-data">
                                <div className="map-container">
                                    <Map
                                        initialViewState={{
                                            longitude: item.location.longitude,
                                            latitude: item.location.latitude,
                                            zoom: 14
                                        }}
                                        style={{ width: '100%', height: '200px' }}
                                        mapStyle="mapbox://styles/mapbox/streets-v11"
                                        mapboxAccessToken={MAPBOX_TOKEN}
                                        scrollZoom={false}
                                        doubleClickZoom={false}
                                        dragPan={false}
                                        dragRotate={false}
                                        touchZoomRotate={false}
                                    >
                                        <Marker
                                            longitude={item.location.longitude}
                                            latitude={item.location.latitude}
                                            anchor="center"
                                        >
                                            <img src={redMarker} alt="Marker" className="custom-marker" />
                                        </Marker>
                                    </Map>
                                </div>
                                <Row className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                    <Col>
                                        <h4>{item.location.market}</h4>
                                        <p>{`${item.location?.address}, ${item.location?.addressType}, ${item.location?.city}, ${item.location?.state}`}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                    <Col>
                                        <button
                                            type="button"
                                            className="manage-btn"
                                            onClick={() => handleSubscribe(item.location)}
                                        >
                                            Re-subscribe
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    ))
                )}
                {/* This fetch from nearBy Location */}
                <SubcriptionDetailModal selectedLocationId={selectedLocationId} show={show} handleClose={handleClose} />
            </Row>
        </div>
    );
};

export default PreviousSubscriptions;
