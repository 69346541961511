import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './AvailableSubscriptions.scss';
import { Col, Row } from 'react-bootstrap';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import redMarker from '@icons/redMarker.svg';
import axiosWrapper from '../../utils/api';
import { API_URL } from '../../utils/apiUrl';
import { useSelector } from 'react-redux';
import SubcriptionDetailModal from '../Modals/SubcriptionDetailModal/SubcriptionDetailModal';
import { toast } from 'react-toastify';

// Custom arrow components
const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                backgroundImage: "url('https://i.ibb.co/xMyLwVX/Vector.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '20px',
                height: '20px',
                zIndex: 1
            }}
            onClick={onClick}
        />
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                backgroundImage: "url('https://i.ibb.co/hfH2qrt/right-icon.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '20px',
                height: '20px',
                zIndex: 1
            }}
            onClick={onClick}
        />
    );
};

const AvailableSubscriptions = ({ isMobile }) => {
    const [availableLocations, setAvailableLocations] = useState([]);
    const { userToken } = useSelector((state) => state.auth);
    const [show, setShow] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(null);

    const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

    useEffect(() => {
        fetchAvailableSubscriptions();
    }, []);

    const fetchAvailableSubscriptions = async () => {
        try {
            const response = await axiosWrapper('GET', API_URL.GET_AVAILABLE_LOCATIONS, {}, userToken);
            setAvailableLocations(response.data);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const slidesToShow = isMobile ? 1 : 3;

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const handleClose = () => setShow(false);
    const handleSubscribe = (item) => {
        setShow(true);
        setSelectedLocationId(item?._id); // Use the unique identifier of the location
    };

    return (
        <div className="available-content">
            {isMobile ? (
                <div className="slide-item-mob">
                    <Row>
                        {availableLocations.length > 0 ? (
                            availableLocations?.map((item) => (
                                <Col key={item._id} xs={6} sm={6} className="mb-3">
                                    <div className="active-data">
                                        <div className="map-container">
                                            <Map
                                                initialViewState={{
                                                    longitude: item.longitude,
                                                    latitude: item.latitude,
                                                    zoom: 14
                                                }}
                                                style={{ width: '100%', height: '200px' }}
                                                mapStyle="mapbox://styles/mapbox/streets-v11"
                                                mapboxAccessToken={MAPBOX_TOKEN}
                                                scrollZoom={false}
                                                doubleClickZoom={false}
                                                dragPan={false}
                                                dragRotate={false}
                                                touchZoomRotate={false}
                                            >
                                                <Marker
                                                    longitude={item.longitude}
                                                    latitude={item.latitude}
                                                    anchor="center"
                                                >
                                                    <img src={redMarker} alt="Marker" className="custom-marker" />
                                                </Marker>
                                            </Map>
                                        </div>
                                        <Row className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                            <Col>
                                                <h4>{item.market}</h4>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                            <Col>
                                                <button
                                                    type="button"
                                                    className="manage-btn"
                                                    onClick={() => handleSubscribe(item)}
                                                >
                                                    Subscribe
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            ))
                        ) : (
                            <p className="text-center">No available subscriptions found.</p>
                        )}
                    </Row>
                </div>
            ) : (
                <>
                    {availableLocations.length === 0 ? (
                        <p className="text-center">No available subscriptions found.</p>
                    ) : (
                        <>
                            <Slider {...settings}>
                                {availableLocations?.map((item) => (
                                    <div key={item._id} className="slide-item">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <div className="active-data">
                                                    <div className="map-container">
                                                        <Map
                                                            initialViewState={{
                                                                longitude: item.longitude,
                                                                latitude: item.latitude,
                                                                zoom: 14
                                                            }}
                                                            style={{ width: '100%', height: '200px' }}
                                                            mapStyle="mapbox://styles/mapbox/streets-v11"
                                                            mapboxAccessToken={MAPBOX_TOKEN}
                                                            scrollZoom={false}
                                                            doubleClickZoom={false}
                                                            dragPan={false}
                                                            dragRotate={false}
                                                            touchZoomRotate={false}
                                                        >
                                                            <Marker
                                                                longitude={item.longitude}
                                                                latitude={item.latitude}
                                                                anchor="center"
                                                            >
                                                                <img
                                                                    src={redMarker}
                                                                    alt="Marker"
                                                                    className="custom-marker"
                                                                />
                                                            </Marker>
                                                        </Map>
                                                    </div>
                                                    <Row className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                                        <Col>
                                                            <h4>{item.market}</h4>
                                                            <p>{`${item.address}, ${item.addressType}, ${item.city}, ${item.state}`}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                                        <Col xs={12} sm={6}>
                                                            {/* Commenting out for future use */}
                                                            {/* <button
                                                                type="button"
                                                                className="cancel-btn"
                                                                onClick={() =>
                                                                    navigate('/parker/subscription-details', {
                                                                        state: { locationId: item._id }
                                                                    })
                                                                }
                                                            >
                                                                Buy Now
                                                            </button> */}
                                                        </Col>
                                                        <Col xs={12} sm={12} className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                                            <button
                                                                type="button"
                                                                className="manage-btn"
                                                                onClick={() => handleSubscribe(item)}
                                                            >
                                                                Subscribe
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </Slider>
                            <SubcriptionDetailModal
                                selectedLocationId={selectedLocationId}
                                show={show}
                                handleClose={handleClose}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default AvailableSubscriptions;
