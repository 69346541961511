import masterCard from '@icons/mastercard.svg';
import { Row } from 'react-bootstrap';
import './WebView.scss';

const WebView = () => {
    return (
        <div className="view-location-modal-body">
            <section className="view-location-content">
                <div className="heading-section">Visit Details</div>
                <Row className="data-section">
                    <div className="image-container-content">
                        <Row className="content-row">
                            <p className="title">License Plate # </p>
                            <p className="text-data">FL029RF</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Date In :</p>
                            <p className="text-data">October 10, 2024</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Date Out :</p>
                            <p className="text-data">October 11, 2024</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Time In :</p>
                            <p className="text-data">01:30 am</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Time Out :</p>
                            <p className="text-data">01:54 am</p>
                        </Row>
                    </div>
                </Row>
                <div className="heading-section">Parking Charges</div>
                <Row className="data-section">
                    <div className="address-content">
                        <Row className="content-row">
                            <p className="title">Duration :</p>
                            <p className="text-data">$12.00</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Transaction Fee : </p>
                            <p className="text-data">$0.45</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Sales Tax :</p>
                            <p className="text-data">0.94</p>
                        </Row>
                    </div>
                </Row>
                <div className="heading-section">Total</div>
                <Row className="data-section">
                    <div className="address-content">
                        <Row className="content-row">
                            <p className="title">Card Type :</p>
                            <div className="masterCard">
                                <img src={masterCard} alt="masterCard" />
                            </div>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Last 4</p>
                            <p className="text-data">7125</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Total :</p>
                            <p className="text-data">$13.39</p>
                        </Row>
                    </div>
                </Row>
            </section>
        </div>
    );
};

export default WebView;
