import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosWrapper from '../../utils/api';
import { API_URL } from '../../utils/apiUrl';

export const loginUser = createAsyncThunk('auth/login', async ({ email, password }, { rejectWithValue }) => {
    try {
        const { data } = await axiosWrapper('post', `${import.meta.env.VITE_API_URL}/api/user/login/email`, {
            email,
            password
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const loginParkerUser = createAsyncThunk(
    'auth/login',
    async ({ phoneNumber, password, isRememberMe }, { rejectWithValue }) => {
        try {
            const { data } = await axiosWrapper('post', API_URL.LOGIN, {
                phoneNumber,
                password,
                isRememberMe
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const registerUser = createAsyncThunk(
    'auth/register',
    async ({ email, password, name }, { rejectWithValue }) => {
        try {
            const { data } = await axiosWrapper('post', `${import.meta.env.VITE_API_URL}/api/user/register`, {
                email,
                password,
                name
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const registerDetails = createAsyncThunk('auth/register', async (payload, { rejectWithValue }) => {
    try {
        const response = await axiosWrapper('post', API_URL.REGISTER_DETAILS, payload);
        return response;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const addVehicleDetails = createAsyncThunk('parker/addVehicle', async (payload, { rejectWithValue }) => {
    try {
        const response = await axiosWrapper('post', API_URL.ADD_VEHICLE, payload);
        return response;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
