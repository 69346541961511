import { useEffect, useState } from 'react';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import { ErrorMessage, Field, Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { reactSelectStyle } from '../../AddLocationForm/RatesForm/utils';
import { useSelector } from 'react-redux';
import './AddVehicle.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosWrapper from '../../../utils/api'; // Adjust the path as needed
import { API_URL } from '../../../utils/apiUrl';
import { provinceOptions } from '../../../data/constants';
import { State } from 'country-state-city';
const currentYear = new Date().getFullYear();

const AddVehicle = () => {
    const navigate = useNavigate();
    const { loading, userToken } = useSelector((state) => state.auth);
    const location = useLocation();
    const { vehicleId } = location.state || {};
    const [states, setStates] = useState([]);

    const [initialValues, setInitialValues] = useState({
        vehicleMake: '',
        licensePlate: '',
        province: 'Florida',
        model: '',
        year: '',
        color: ''
    });

    const isEditMode = Boolean(vehicleId);

    const validationSchema = Yup.object().shape({
        vehicleMake: Yup.string()
            .required('Vehicle make is required')
            .max(50, 'Vehicle make cannot exceed 50 characters'),

        licensePlate: Yup.string()
            .required('License plate number is required')
            .max(10, 'License plate cannot exceed 10 characters'),

        province: Yup.string()
            .required('State/Province is required')
            .max(50, 'State/Province cannot exceed 50 characters'),

        model: Yup.string().required('Car model is required').max(50, 'Car model cannot exceed 50 characters'),

        year: Yup.number()
            .typeError('Year must be a number')
            .required('Year is required')
            .integer('Year must be an integer')
            .min(1886, 'Year must be later than 1885') // The year the first automobile was patented
            .max(currentYear + 1, `Year cannot be later than ${currentYear + 1}`),

        color: Yup.string()
            .required('Color is required')
            .matches(/^[a-zA-Z\s]+$/, 'Color can only contain letters and spaces')
            .max(30, 'Color cannot exceed 30 characters')
    });

    useEffect(() => {
        if (isEditMode) {
            fetchVehicleDetails(vehicleId);
        } else {
            setInitialValues({
                vehicleMake: '',
                licensePlate: '',
                province: 'Florida',
                model: '',
                year: '',
                color: ''
            });
        }
    }, [isEditMode, vehicleId]);

    useEffect(() => {
        // Fetch US states
        const usStates = State.getStatesOfCountry('US');
        const stateOptions = usStates.map((state) => ({
            value: state.name,
            label: state.name
        }));
        setStates(stateOptions);
    }, []);

    const fetchVehicleDetails = async (id) => {
        try {
            const { data } = await axiosWrapper('GET', API_URL.GET_VEHICLE_BY_ID(id), {}, userToken);

            setInitialValues({
                vehicleMake: data?.vehicleMake,
                licensePlate: data?.licensePlate,
                province: data?.province,
                model: data?.model,
                year: String(data?.year),
                color: data?.color
            });
        } catch (error) {
            // Handle error if needed
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            if (isEditMode) {
                await axiosWrapper('PUT', API_URL.UPDATE_VEHICLE(vehicleId), values, userToken);
            } else {
                await axiosWrapper('POST', API_URL.CREATE_VEHICLE, values, userToken);
            }
            setSubmitting(false);
            navigate('/parker/vehicles');
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <>
            <div className="breadcrumb-vehicles">
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate('/parker/vehicles')}>Vehicles</Breadcrumb.Item>
                    <Breadcrumb.Item active>{isEditMode ? 'Edit' : 'Add'} Vehicle</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="add-vehicle-modal">
                <div className="session-title mb-4">
                    <h4>{isEditMode ? 'Edit' : 'Add'} Vehicle</h4>
                </div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange={true}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <FormikForm className="information-form">
                            <>
                                <div className="fields-row">
                                    <div className="field-container">
                                        <label htmlFor="vehicleMake">Vehicle Make*</label>
                                        <Field
                                            name="vehicleMake"
                                            placeholder="Enter vehicle name e.g ‘Honda’"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            name={'vehicleMake'}
                                            component="span"
                                            className="validation-error"
                                        />
                                    </div>

                                    <div className="field-container">
                                        <label htmlFor="plateNo">License Plate #*</label>
                                        <Field
                                            name="licensePlate"
                                            placeholder="Enter car plate number e.g ‘FL-029-RF’"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            name={'licensePlate'}
                                            component="span"
                                            className="validation-error"
                                        />
                                    </div>
                                    <div className="field-container">
                                        <div className="field-container">
                                            <label htmlFor="province">Province/State*</label>
                                            <Select
                                                name="province"
                                                options={states}
                                                className="multi-select-field"
                                                classNamePrefix="__select-days"
                                                placeholder="Select Province/State"
                                                value={states.find((state) => state.value === values.province) || null}
                                                onChange={(selectedOption) =>
                                                    setFieldValue('province', selectedOption.value)
                                                }
                                            />
                                            <ErrorMessage
                                                name={'province'}
                                                component="span"
                                                className="validation-error"
                                            />
                                        </div>
                                    </div>

                                    <div className="field-container">
                                        <label htmlFor="model">Model*</label>
                                        <Field
                                            name="model"
                                            placeholder="Enter car model"
                                            type="text"
                                            className="form-input"
                                        />
                                        <ErrorMessage name="model" component="span" className="validation-error" />
                                    </div>
                                    <div className="field-container">
                                        <label htmlFor="year">Year*</label>
                                        <Field
                                            name="year"
                                            placeholder="Enter year e.g., '2023'"
                                            type="number"
                                            className="form-control"
                                            min="1886"
                                            max={currentYear + 1}
                                        />
                                        <ErrorMessage name="year" component="span" className="validation-error" />
                                    </div>
                                    <div className="field-container">
                                        <label htmlFor="color">Color*</label>
                                        <Field
                                            name="color"
                                            placeholder="Enter color e.g. 'Red'"
                                            type="text"
                                            className="form-input"
                                        />
                                        <ErrorMessage name="color" component="span" className="validation-error" />
                                    </div>
                                </div>
                                <div className="buttons-row mb-5 mt-4">
                                    <button type="submit" className="save-btn" disabled={loading}>
                                        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
                                    </button>
                                </div>
                            </>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default AddVehicle;
