import { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MainPanel } from '@components/Dashboard';
import Loading from '@components/Loading/Loading';
import MobilePanel from '@components/Dashboard/MobilePanel/MobilePanel';
import UserTopBar from '../../components/UserTopBar/UserTopBar';
import { useMediaQuery } from 'react-responsive';
import MobileTopBar from '../../components/MobileTopBar/MobileTopBar';
import ParkerSidebar from '../../components/ParkerSidebar/ParkerSidebar';

const UserLayout = () => {
    const navigate = useNavigate();

    const { isLoggedIn, userInfo } = useSelector((state) => state.auth);
    const role = userInfo?.role;

    useEffect(() => {
        if (!isLoggedIn || role !== 'PARKER') {
            navigate('/parker/auth');
        }
    }, []);

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    return (
        <>
            {isMobile ? (
                <>
                    <MobileTopBar />
                    <ParkerSidebar />
                    <Suspense fallback={<Loading centered size="lg" />}>
                        <MobilePanel>
                            <Outlet />
                        </MobilePanel>
                    </Suspense>
                </>
            ) : (
                <>
                    <UserTopBar />
                    <ParkerSidebar />
                    <Suspense fallback={<Loading centered size="lg" />}>
                        <MainPanel isParker={true}>
                            <Outlet />
                        </MainPanel>
                    </Suspense>
                </>
            )}
        </>
    );
};

export default UserLayout;
