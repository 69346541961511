import { Breadcrumb } from 'react-bootstrap';
import './SessionViewDetails.scss';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import WebView from './WebView/WebView';
import SessionDetailMobile from '../../SessionDetailMobile/SessionDetailMobile';

const SessionViewDetails = () => {
    const navigate = useNavigate();

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    return (
        <>
            <div className="breadcrumb-customs">
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate('/parker/session-details')}>Activity</Breadcrumb.Item>
                    <Breadcrumb.Item active>Session Details</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {isMobile ? <SessionDetailMobile /> : <WebView />}
        </>
    );
};

export default SessionViewDetails;
