import { Breadcrumb, Col, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage, Field, Formik, Form as FormikForm, FieldArray } from 'formik';
import Select from 'react-select';
import { reactSelectStyle } from '../AddLocationForm/RatesForm/utils';
import { useEffect, useState } from 'react';
import axiosWrapper from '../../utils/api';
import { API_URL } from '../../utils/apiUrl';
import Loading from '../Loading/Loading';
import './EditParker.scss';
import { State } from 'country-state-city';

const EditParker = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { userToken, loading: authLoading } = useSelector((state) => state.auth);
    const [initialValues, setInitialValues] = useState({
        name: '',
        email: '',
        phoneNo: '',
        vehicles: []
    });
    const [loading, setLoading] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);

    useEffect(() => {
        const fetchStates = () => {
            const usStates = State.getStatesOfCountry('US');
            const formattedStates = usStates.map((state) => ({
                value: state.name,
                label: state.name
            }));
            setStateOptions(formattedStates);
        };

        fetchStates();
    }, []);
    const fetchParker = async () => {
        setLoading(true);
        try {
            const response = await axiosWrapper('GET', `${API_URL.GET_PARKER_BY_ID}/${id}`, null, userToken);
            const parkerData = response.data;

            // Map vehicles to form fields
            const vehicles = parkerData.vehicles.map((vehicle) => ({
                id: vehicle._id, // Include ID for existing vehicles
                vehicleName: vehicle.vehicleMake,
                plateNo: vehicle.licensePlate,
                state: vehicle.province
            }));

            setInitialValues({
                name: parkerData.name || '',
                email: parkerData.email || '',
                phoneNo: parkerData.phoneNumber || '',
                vehicles: vehicles,
                subscriptions: parkerData.subscriptions || [],
                sessions: parkerData.sessions || 0
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParker();
    }, [id]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phoneNo: Yup.string().required('Phone number is required'),
        vehicles: Yup.array().of(
            Yup.object().shape({
                vehicleName: Yup.string().required('Vehicle name is required'),
                plateNo: Yup.string().required('Car plate number is required'),
                state: Yup.string().required('State is required')
            })
        )
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            // Prepare data for backend
            const payload = {
                name: values.name,
                email: values.email,
                phoneNo: values.phoneNo,
                vehicles: values.vehicles
            };

            await axiosWrapper('PUT', `${API_URL.UPDATE_PARKER_BY_ID}/${id}`, payload, userToken);

            navigate('/admin/parkers');
        } catch (error) {
            setSubmitting(false);
        }
    };

    if (loading || authLoading) {
        return <Loading centered fullWidth />;
    }

    return (
        <div className="edit-parker">
            <div className="breadcrumb-parker">
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate('/admin/parkers')}>Parkers</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Parker</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="edit-vehicle-modal mt-4">
                <div className="session-title mb-4">
                    <h4>Profile ID</h4>
                </div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange={true}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <FormikForm className="information-form">
                            <>
                                <div className="fields-row">
                                    <div className="field-container">
                                        <label htmlFor="name">Name</label>
                                        <Field name="name" placeholder="Ralph Edwards" type="text" />
                                        <ErrorMessage name={'name'} component="span" className="validation-error" />
                                    </div>

                                    <div className="field-container">
                                        <label htmlFor="email">Email</label>
                                        <Field
                                            name="email"
                                            placeholder="Enter car plate number e.g ‘FL-029-RF’"
                                            type="text"
                                        />
                                        <ErrorMessage name={'email'} component="span" className="validation-error" />
                                    </div>
                                    <div className="field-container">
                                        <label htmlFor="phoneNo">Phone No</label>
                                        <Field
                                            name="phoneNo"
                                            placeholder="Enter car plate number e.g ‘FL-029-RF’"
                                            type="text"
                                        />
                                        <ErrorMessage name={'phoneNo'} component="span" className="validation-error" />
                                    </div>
                                </div>

                                <div className="session-title mb-4  mt-4">
                                    <h4>Vehicles Detail</h4>
                                </div>

                                <FieldArray name="vehicles">
                                    {() => (
                                        <div className="fields-row">
                                            {values.vehicles && values.vehicles.length > 0 ? (
                                                values.vehicles.map((vehicle, index) => (
                                                    <Row key={index} className="mb-2 width-handler">
                                                        <Col
                                                            xs={12}
                                                            sm={12}
                                                            md={6}
                                                            lg={6}
                                                            xl={4}
                                                            className="d-flex mb-3 mb-md-0 mb-lg-0"
                                                        >
                                                            <div className="count-section">
                                                                <h4 className="title text-center">No.</h4>
                                                                <p className="text-data">{index + 1}</p>
                                                            </div>
                                                            <div className="name-section">
                                                                <div className="field-container">
                                                                    <h4 className="title">Vehicle Name</h4>
                                                                    <Field
                                                                        name={`vehicles[${index}].vehicleName`}
                                                                        placeholder="Honda"
                                                                        type="text"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`vehicles[${index}].vehicleName`}
                                                                        component="span"
                                                                        className="validation-error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                                            <div className="field-container">
                                                                <h4 className="title">License Plate #</h4>
                                                                <Field
                                                                    name={`vehicles[${index}].plateNo`}
                                                                    placeholder="FL-029-RF"
                                                                    type="text"
                                                                />
                                                                <ErrorMessage
                                                                    name={`vehicles[${index}].plateNo`}
                                                                    component="span"
                                                                    className="validation-error"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            sm={12}
                                                            md={6}
                                                            lg={6}
                                                            xl={4}
                                                            className="mt-3 mt-xl-0"
                                                        >
                                                            <div className="field-container">
                                                                <h4 className="title">Province/State</h4>
                                                                <div className="field-container">
                                                                    <Select
                                                                        name={`vehicles[${index}].state`}
                                                                        options={stateOptions}
                                                                        className="multi-select-field"
                                                                        classNamePrefix="__select-days"
                                                                        placeholder="Select Province/State"
                                                                        value={
                                                                            stateOptions.find(
                                                                                (option) =>
                                                                                    option.value === vehicle.state
                                                                            ) || null
                                                                        }
                                                                        onChange={(selectedOption) => {
                                                                            setFieldValue(
                                                                                `vehicles[${index}].state`,
                                                                                selectedOption?.value
                                                                            );
                                                                        }}
                                                                        styles={reactSelectStyle}
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`vehicles[${index}].state`}
                                                                        component="span"
                                                                        className="validation-error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))
                                            ) : (
                                                <p>No vehicles available.</p>
                                            )}
                                        </div>
                                    )}
                                </FieldArray>

                                <div className="session-title mb-4  mt-4">
                                    <h4>Payment History</h4>
                                </div>

                                {values.subscriptions && values.subscriptions.length > 0 ? (
                                    values.subscriptions.map((sub) => (
                                        <Row key={sub._id} className="mb-4">
                                            <Col lg={6} md={6} xs={12} sm={6}>
                                                <Row className="mt-3">
                                                    <Col lg={4} xs={6}>
                                                        <h4 className="title-text">Zone</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-area">{sub.location.zone}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg={4} xs={6}>
                                                        <h4 className="title-text">Destination</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-area">{sub.location.address}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg={4} xs={6}>
                                                        <h4 className="title-text">Plate No</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-area">
                                                            {sub.vehicles.map((v) => v.licensePlate).join(', ')}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg={4} xs={6}>
                                                        <h4 className="title-text">Amount</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-area">${(sub.amount / 100).toFixed(2)}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg={4} xs={6}>
                                                        <h4 className="title-text">Currency</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-area">{sub.currency}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg={4} xs={6}>
                                                        <h4 className="title-text">Source</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-area">VCard</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={6} md={6} xs={12} sm={6}>
                                                <Row className="mt-3">
                                                    <Col lg={4} xs={6}>
                                                        <h4 className="title-text">Time</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-area">
                                                            {new Date(sub.createdAt).toLocaleTimeString()}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg={4} xs={6}>
                                                        <h4 className="title-text">Payment Date</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-area">
                                                            {new Date(sub.createdAt).toLocaleDateString()}
                                                        </p>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col lg={4} xs={6}>
                                                        <h4 className="title-text">Subscription</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-area">{sub.name || sub.location.address}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))
                                ) : (
                                    <p>No payment history available.</p>
                                )}

                                <div className="buttons-row mt-4 mb-3">
                                    <button type="submit" className="save-btn" disabled={loading}>
                                        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Update'}
                                    </button>
                                </div>
                            </>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default EditParker;
