import axiosWrapper from '../../utils/api';

const getPaginatedPaymentsInfo = async ({ skip, take }) => {
    try {
        const data = await axiosWrapper(
            'get',
            `${import.meta.env.VITE_API_URL}/api/payment/getPaginatedPayments?skip=${skip}&&take=${take}`
        );
        return { status: true, data: data };
    } catch (error) {
        return { status: false, data: error?.message };
    }
};
const getAllPayments = async () => {
    try {
        const data = await axiosWrapper(
            'get',
            `${import.meta.env.VITE_API_URL}/api/payment/all`,
            null,
            localStorage.getItem('authToken')
        );
        return { status: true, data: data };
    } catch (error) {
        return { status: false, data: error?.message };
    }
};
export { getPaginatedPaymentsInfo, getAllPayments };
