//options for selecting days
export const days = [
    { value: 'monday', label: 'Monday' },
    { value: 'tuesday', label: 'Tuesday' },
    { value: 'wednesday', label: 'Wednesday' },
    { value: 'thursday', label: 'Thursday' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' },
    { value: 'sunday', label: 'Sunday' }
];

//options for selecting hours
export const hours = [
    { index: 1, value: '00:00', label: '12:00 AM' },
    { index: 2, value: '00:30', label: '12:30 AM' },
    { index: 3, value: '01:00', label: '1:00 AM' },
    { index: 4, value: '01:30', label: '1:30 AM' },
    { index: 5, value: '02:00', label: '2:00 AM' },
    { index: 6, value: '02:30', label: '2:30 AM' },
    { index: 7, value: '03:00', label: '3:00 AM' },
    { index: 8, value: '03:30', label: '3:30 AM' },
    { index: 9, value: '04:00', label: '4:00 AM' },
    { index: 10, value: '04:30', label: '4:30 AM' },
    { index: 11, value: '05:00', label: '5:00 AM' },
    { index: 12, value: '05:30', label: '5:30 AM' },
    { index: 13, value: '06:00', label: '6:00 AM' },
    { index: 14, value: '06:30', label: '6:30 AM' },
    { index: 15, value: '07:00', label: '7:00 AM' },
    { index: 16, value: '07:30', label: '7:30 AM' },
    { index: 17, value: '08:00', label: '8:00 AM' },
    { index: 18, value: '08:30', label: '8:30 AM' },
    { index: 19, value: '09:00', label: '9:00 AM' },
    { index: 20, value: '09:30', label: '9:30 AM' },
    { index: 21, value: '10:00', label: '10:00 AM' },
    { index: 22, value: '10:30', label: '10:30 AM' },
    { index: 23, value: '11:00', label: '11:00 AM' },
    { index: 24, value: '11:30', label: '11:30 AM' },
    { index: 25, value: '12:00', label: '12:00 PM' },
    { index: 26, value: '12:30', label: '12:30 PM' },
    { index: 27, value: '13:00', label: '1:00 PM' },
    { index: 28, value: '13:30', label: '1:30 PM' },
    { index: 29, value: '14:00', label: '2:00 PM' },
    { index: 30, value: '14:30', label: '2:30 PM' },
    { index: 31, value: '15:00', label: '3:00 PM' },
    { index: 32, value: '15:30', label: '3:30 PM' },
    { index: 33, value: '16:00', label: '4:00 PM' },
    { index: 34, value: '16:30', label: '4:30 PM' },
    { index: 35, value: '17:00', label: '5:00 PM' },
    { index: 36, value: '17:30', label: '5:30 PM' },
    { index: 37, value: '18:00', label: '6:00 PM' },
    { index: 38, value: '18:30', label: '6:30 PM' },
    { index: 39, value: '19:00', label: '7:00 PM' },
    { index: 40, value: '19:30', label: '7:30 PM' },
    { index: 41, value: '20:00', label: '8:00 PM' },
    { index: 42, value: '20:30', label: '8:30 PM' },
    { index: 43, value: '21:00', label: '9:00 PM' },
    { index: 44, value: '21:30', label: '9:30 PM' },
    { index: 45, value: '22:00', label: '10:00 PM' },
    { index: 46, value: '22:30', label: '10:30 PM' },
    { index: 47, value: '23:00', label: '11:00 PM' },
    { index: 48, value: '23:30', label: '11:30 PM' },
    { index: 49, value: '23:59', label: '11:59 PM' }
];

export function getDatesToShow() {
    const datesToShow = [];
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth(); // 0-based index
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    for (let day = 1; day <= daysInMonth; day++) {
        const value = new Date(year, month, day).toLocaleDateString('en-US');
        const label = day.toString();
        datesToShow.push({ value, label });
    }

    return datesToShow;
}
export const reactSelectStyle = {
    control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        border: '1px solid #aeadad'
    })
};
//  return an arrary from 1, to 30 like [1,2,3,4] with js arrary prototype
export const getDays = () => {
    const days = [];
    days?.push({ value: 0, label: 'Select Days' });
    for (let i = 1; i <= 30; i++) {
        days.push({ value: i, label: i });
    }
    return days;
    // return the value in the form of array of object {value: 1, label: 1}
};
